const GETCREATEDETAILS_START = "GETCREATEDETAILS_START";
const GETCREATEDETAILS_SUCC = "GETCREATEDETAILS_SUCC";
const GETCREATEDETAILS_FAIL = "GETCREATEDETAILS_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GETCREATEDETAILS_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case GETCREATEDETAILS_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GETCREATEDETAILS_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
