import React from 'react';
import './PaginationDot.css';

class PaginationDot extends React.Component{

    constructor(props){
        super(props);
        this.state={
            selected_dot:this.props.selected_dot,
        };
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        if(prevstate.selected_dot !== nextprops.selected_dot){
            return{
                selected_dot:nextprops.selected_dot
            }
        }else{
            return null;
        }   
    }

    handleClick=(ref,index)=>{
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        this.setState({selected_dot:index})
    }

    render(){
        let dotclass = "dot";
        return(
            this.props.refvalues.map((value,index)=>{
                if(this.state.selected_dot === index){
                    dotclass = 'dot selected'
                }else{
                    dotclass = "dot";
                }
                return(
                    <div key={'pagination_dot+'+index} onClick={()=>this.handleClick(value,index)} className={dotclass}></div>
                );
            })
        );
    }
}

export default PaginationDot;