import React from 'react';
import './SquareCard.css';

const squareCard = props =>{
    return(
        <div className="squarecard">
            <img style={{width:'100%'}} alt={"CardImage"} src={require('../../images/larynx_ai2.png')}/>
            <div style={{padding:'10px'}}>
                <p className="card_title">{props.data.title}</p>
                <p className="card_desc">{props.data.desc}</p>
            </div>
        </div>
    );
};

export default squareCard;