import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const LOGIN_USER_START = "LOGIN_USER_START";
const LOGIN_USER_SUCC = "LOGIN_USER_SUCC";
const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export default function login(email,password) {

    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'login',
                    'method':'POST'
                },
                body: JSON.stringify({
                    "email":email,
                    "password_hash":password
                }),
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        )
    }
}

function apiCallStart() {
    return {
        type: LOGIN_USER_START
    }
}

function apiCallSuccess(data) {
    return {
        type: LOGIN_USER_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: LOGIN_USER_FAIL,
        error
    }
}
