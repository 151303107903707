import React from "react";
import "./Blip.css";
import Fade from "react-reveal/Fade";
import { trackPromise } from "react-promise-tracker";
import { Modal } from "react-bootstrap";
import { HOST } from "../../../constants/constants";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Blip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userdata: JSON.parse(localStorage.getItem("user_data")),
      blip: this.props.location.state.blip,
      project: this.props.location.state.project,
      playerModal: false,
      reportMessage: "",
      isSendingReport: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    console.log(this.state.blip.blip_id);
    console.log(this.state.blip.is_reported);
  }

  copyLink() {
    var copyLink = document.getElementById("blip_audio");
    console.log(copyLink.src);
    copyLink.select();
    document.execCommand("copy");
  }

  editBlip() {
    this.props.history.replace({
      pathname: "/editor",
      state: {
        blip: this.state.blip,
        project: this.state.project,
      },
    });
  }

  deleteBlip() {
    console.log("delete blip");
    trackPromise(
      fetch(HOST + "/api_handler.php?timestamp=" + new Date().getTime(), {
        method: "POST",
        headers: {
          gateway: "0",
          path: "text2speech/blip/delete",
          method: "POST",
          token: this.state.userdata.token,
        },
        body: JSON.stringify({
          blip_id: this.state.blip.blip_id,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonresponse) => {
          console.log(jsonresponse);
          if (jsonresponse.status) {
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        }),
      "modal_spinner"
    );
  }

  sendReport() {
    // console.log("Send Report");
    // console.log("Blip ID", this.state.blip.blip_id);
    // console.log("Message ", this.state.reportMessage);
    this.setState((state, props) => {
      return {
        isSendingReport: true,
      };
    });
    trackPromise(
      fetch(HOST + "/api_handler.php?timestamp=" + new Date().getTime(), {
        method: "POST",
        headers: {
          gateway: "0",
          path: "text2speech/blip/report",
          method: "POST",
          token: this.state.userdata.token,
        },
        body: JSON.stringify({
          blip_id: this.state.blip.blip_id,
          error_log: this.state.reportMessage,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonresponse) => {
          this.setState((state, props) => {
            return {
              playerModal: false,
            };
          });
          this.props.history.goBack();
        })
        .catch((error) => {
          console.log(error);
        }),
      "modal_spinner"
    );
  }

  renderBlipData() {
    return (
      <div className="blip_container">
        <div className="blip_infocontainer">
          <div
            style={{
              display: "flex",
              textTransform: "none",
              alignItems: "center",
            }}
            className="headercaption"
          >
            Voice :{" "}
            <div
              style={{
                marginLeft: "5px",
                color: "black",
                fontWeight: "500",
                fontSize: "140%",
              }}
            >
              {this.state.blip.model}
            </div>
          </div>
        </div>
        <div className="blip_buttons_container">
          {/* <div onClick={()=>{this.copyLink()}} className="blip_button">Link</div> */}
          <div
            className="blip_button"
            onClick={() => {
              this.deleteBlip();
            }}
          >
            Delete
          </div>
          <div
            className="blip_button"
            onClick={() => {
              this.editBlip();
            }}
          >
            Edit
          </div>
          {this.state.blip.is_reported === 1 ? (
            <div className="blip_reported">
              <FontAwesomeIcon icon={faExclamationTriangle} /> Reported
            </div>
          ) : (
            <div
              className="blip_report"
              onClick={() => {
                this.setState((state, props) => {
                  return {
                    playerModal: true,
                    isSendingReport: false,
                  };
                });
              }}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} /> Report
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            textTransform: "none",
            alignItems: "center",
            marginLeft: "2%",
          }}
          className="headercaption"
        >
          Text :
        </div>
        <div className="blip_textarea">{this.state.blip.text}</div>
        <div className="blip_audiocontainer">
          <audio
            id="blip_audio"
            ref="blip_audio_tag"
            src={this.state.blip.audio_url}
            controls
          />
        </div>
      </div>
    );
  }

  handlePlayerModal() {
    this.setState((state, props) => {
      return {
        playerModal: false,
      };
    });
  }

  renderPlayerModal() {
    return (
      <Modal
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          this.handlePlayerModal();
        }}
        show={this.state.playerModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{
                marginLeft: "5px",
                fontWeight: "500",
                color: "#F3913D",
              }}
            >
              Report
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="report_container">
            <textarea
              name="message"
              rows="5"
              cols="30"
              className="editor_textbox"
              value={this.state.reportMessage}
              onChange={(event) => {
                // this.handleReportChange();
                this.setState({ reportMessage: event.target.value });
              }}
            ></textarea>
            {this.state.isSendingReport ? (
              <div>Sending...</div>
            ) : (
              <div
                className="modal_report_button"
                onClick={() => {
                  this.sendReport();
                }}
              >
                Send <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div className="blip">
        {this.renderPlayerModal()}
        <div className="blip_headingdiv">
          <Fade timeout={1000} top>
            <div className="blip_mainHeading">
              <div
                style={{
                  marginLeft: "5px",
                  fontWeight: "500",
                  color: "#F3913D",
                }}
              >
                BLIP
              </div>
            </div>
            {/* <div className="blip_download">
                            Download
                        </div> */}
          </Fade>
        </div>
        <div className="project_contentdiv">
          <div className="project_blips_ListingDiv">
            {this.state.blip && this.renderBlipData()}
          </div>
        </div>
      </div>
    );
  }
}

export default Blip;
