const CHECK_EMAIL_START = "CHECK_EMAIL_START";
const CHECK_EMAIL_SUCC = "CHECK_EMAIL_SUCC";
const CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

const initialState = {
    data: {},
    fetchCompleted: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_EMAIL_START: {
            return {
                ...state,
                fetchCompleted: false,
            }
        }
        case CHECK_EMAIL_SUCC: {
            return {
                ...state,
                fetchCompleted: true,
                data: action.data,
            }
        }
        case CHECK_EMAIL_FAIL: {
            return {
                ...state,
                error: action.error,
                fetchCompleted: false,
                fail: true,
            }
        }
        default: return state
    }
}
