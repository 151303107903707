const GET_TRAIN_START = "GET_TRAIN_START";
const GET_TRAIN_SUCC = "GET_TRAIN_SUCC";
const GET_TRAIN_FAIL = "GET_TRAIN_FAIL";

const initialState = {
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TRAIN_START: {
            return {
                ...state,
                isfetching: true,
                data:null,
            }
        }
        case GET_TRAIN_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GET_TRAIN_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
