const LOGIN_USER_START = "LOGIN_USER_START";
const LOGIN_USER_SUCC = "LOGIN_USER_SUCC";
const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case LOGIN_USER_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case LOGIN_USER_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
