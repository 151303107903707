import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GET_PREVTRAIN_START = "GET_PREVTRAIN_START";
const GET_PREVTRAIN_SUCC = "GET_PREVTRAIN_SUCC";
const GET_PREVTRAIN_FAIL = "GET_PREVTRAIN_FAIL";

export default function getPrevTrainData(project) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
            trackPromise(
                fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                    method: 'POST',
                    headers: {
                        'gateway':'1',
                        'path':'traincontent/audit/project_id/'+project.project_id+'/previous/record/content',
                        'method':'GET'
                    }
                })
                    .then((response) => {
                        // if (response.headers.get('content-type').match(/application\/json/)) {
                            return response.json();
                        // }
                        // return response;
                    })
                    .then((jsonresponse) => {
                        console.log(jsonresponse)
                        if(jsonresponse.status){
                            dispatch(apiCallSuccess(jsonresponse.response))
                        }
                    })
                    .catch((error) => dispatch(apiCallFail(error)))
            ,'modal_spinner')
    }
}

function apiCallStart() {
    return {
        type: GET_PREVTRAIN_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GET_PREVTRAIN_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GET_PREVTRAIN_FAIL,
        error
    }
}
