export default {
    "videoUrl":"https://larynx-webdata-v2.s3-us-east-2.amazonaws.com/video_tutorials/Larynx_ai_demo_finalcut_1.mp4",
    "guidelines":[
        "\u2022 The background should be noise free.",
        "\u2022 Make sure the voices are close to the microphone.",
        "\u2022 Conversation must be clear without overtalk.",
        "\u2022 Talkers having clear accents will be helpful."
    ],
    "shortcuts":[
        {
            "key":require('../../images/space-bar-icon.png'),
            "desc":"Recording Start/Stop"
        },
        {
            "key":require('../../images/arrow-down-icon.png'),
            "desc":"Play/Pause"
        },{
            "key":require('../../images/arrow-right-icon.png'),
            "desc":"Next/Skip"
        }
    ]
}