import React from 'react';
import './Home.css'
import Fade from 'react-reveal/Fade';
import {THEME_COLOR} from '../../constants/constants';

import Swiper from '../Swiper/Swiper';
import FeelTheVoice from '../FeelTheVoice/FeelTheVoice';
import Applications from '../Applications/Applications';
import CelebrityVoice from '../CelebrityVoice/CelebrityVoice';
import HomeBenefits from '../HomeBenefits/HomeBenefits';
import Customers from '../Customers/Customers';

import SquareCard from '../SquareCard/SquareCard';
import PaginationDot from '../PaginationDot/PaginationDot';

class Home extends React.Component{

    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.state={
            sideDrawerOpen: false,
            selected_dot: null,
            squarecardarray: [
                {
                title: "Card 1",
                desc: "This is supposed to be a description",
                img: require('../../images/larynx_ai2.png')
                },
                {
                title: "Card 2",
                desc: "This is supposed to be a description",
                img: require('../../images/larynx_ai2.png')
                },
                {
                title: "Card 3",
                desc: "This is supposed to be a description",
                img: require('../../images/larynx_ai2.png')
                },
                {
                title: "Card 4",
                desc: "This is supposed to be a description",
                img: require('../../images/larynx_ai2.png')
                },
            ],
        };
    }

    componentDidMount(){
        // window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll(event) {
        // console.log('the scroll things', event)
        var refarr = [this.section1, this.section2, this.section3, this.section4];
        for (var i in refarr) {
          if (refarr[0].current && refarr[0].current.getBoundingClientRect().top > 100) {
            this.setState({ selected_dot: null })
          } else if (refarr[i].current && refarr[i].current.getBoundingClientRect().top > 60 && refarr[i].current.getBoundingClientRect().top < 100) {
            this.setState({ selected_dot: i })
          }
        }
    };

    renderSquareCards() {
        return (
          <div className="squarecard_container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {this.state.squarecardarray.map((card, index) => {
              return (
                <SquareCard key={index + 'squarecard'} data={card} />
              );
            })}
          </div>
        );
      }

    renderMainScreenComponent() {
        return (
          <div>
            <div className="home_swiper" style={{ width: '100%' }}>
              <Fade timeout={1000} bottom>
                <Swiper history={this.props.history} />
              </Fade>
            </div>
            {/* <div className="paginationdot">
              <PaginationDot refvalues={refvalues}/>
            </div> */}
            <div style={{ height: '100%' ,marginTop:'10px',paddingBottom:'30px'}} ref={this.section1}>
              <Fade timeout={1500} bottom>
                <FeelTheVoice/>
              </Fade>
            </div>
            <div style={{ height: '100%' ,background:THEME_COLOR,}} ref={this.section2}>
              <Applications/>
            </div>
            <div style={{ height: '100%' ,marginBottom:'30px'}} ref={this.section3}>
              <CelebrityVoice/>
            </div>
            <div style={{ height: '100%' ,marginBottom:'30px',background:'wheat',paddingBottom:'3%'}} ref={this.section4}>
              <HomeBenefits/>
            </div>
            {/* <div style={{ height: '100%' ,marginBottom:'30px',}} ref={this.section5}>
              <Customers/>
            </div> */}
            {/* <div style={{ height: '100%' ,}} ref={this.section4}>
              {this.renderSquareCards()}
            </div> */}
          </div>
        );
    }

    render(){
        let refvalues = [this.section1, this.section2, this.section3, this.section4];
        let paginationdotclass = (this.state.selected_dot == null) ? "paginationdot invisible" : "paginationdot";
        return (
            <div>
                <div className={paginationdotclass}>
                  {/* <PaginationDot selected_dot={this.state.selected_dot} refvalues={refvalues} /> */}
                </div>
                  {this.renderMainScreenComponent()}
            </div>
        );
    }
}

export default Home;