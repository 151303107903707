const CREATE_PROJECT_START = "CREATE_PROJECT_START";
const CREATE_PROJECT_SUCC = "CREATE_PROJECT_SUCC";
const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_PROJECT_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case CREATE_PROJECT_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case CREATE_PROJECT_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
