const GETMODALDETAILS_START = "GETMODALDETAILS_START";
const GETMODALDETAILS_SUCC = "GETMODALDETAILS_SUCC";
const GETMODALDETAILS_FAIL = "GETMODALDETAILS_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GETMODALDETAILS_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case GETMODALDETAILS_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GETMODALDETAILS_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
