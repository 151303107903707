import React from 'react';

class Caption extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        var props = this.props;
        return(
            <div style={{marginBottom:'2%'}}>
                <div style={{display:'flex',justifyContent:'center',padding:'40px 0px 10px 0px'}}>
                    <h2 style={{color:props.color,textAlign:'center'}}>{props.title}</h2>
                </div>
                <p className="description" style={{color:props.color}}>{props.description}</p>
            </div>
        );
    }
}

export default Caption;