import React from 'react';
// import 'react-id-swiper/lib/styles/css/swiper.css';
import './Swiper.css'
import 'swiper/css/swiper.css'

import Swiper from 'react-id-swiper';

// const swiper =props=>{


const params = {
    lazy: true,
    speed: 700,
    slidesPerView: 1,
    spaceBetween: 0,
    // loop: true,
    // autoplay: {
    //     delay: 4000,
    //     disableOnInteraction: false
    // },
    // freeMode: true,
    // mousewheel: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => <div style={{ outline: 'none' }} className="swiper-button-prev"></div>,
    renderNextButton: () => <div style={{ outline: 'none' }} className="swiper-button-next"></div>,
}

class swiper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        window.addEventListener("resize", this.update);
    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.setState((state, props) => {
            return {
                screenHeight: window.innerHeight,
                screenWidth: window.innerWidth
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.update)
    }

    onMarketPress(type) {
        this.props.history.push({
            pathname: '/market',
            state: { type: type }
        });
    };

    onRobotLearnMore() {
        this.props.history.push({
            pathname: '/robots',
        });
    }

    render() {
        return (
            <Swiper {...params}>
                <div>
                    <div className="swiper_slide">
                        <div className="swiper_slide_frame">
                            <div className="swiper_slide_headings_black">
                                {/* <h3 style={{color:'black'}}>Rumours are true. Human voice doesn't need a Human Being</h3>
                        <h4>Larynx.ai will learn and re-generate human voice</h4> */}
                                <h1 style={{ fontWeight: 'bold' }}>Rumors are true...</h1>
                                {this.state.screenWidth <= 700 &&
                                    <img className="swiper_slide_image" src={require('../../images/parrot.png')} alt="parrot_image" />
                                }
                                <h4 style={{ color: 'grey' }}> We create Voice just like Human beings.</h4>
                                {/* {this.state.screenWidth >700 &&
                            <h4 style={{color:'grey'}}>Choose the voice and power up your business with Larynx.ai</h4>
                        } */}
                            </div>
                            <div className="swiper_slide_buttons">
                                <button className="swiper_button swiper_mainimage_button">Learn More</button>
                                <button className="swiper_mainimage_button swiper_button">Feel it</button>
                            </div>
                        </div>
                        {this.state.screenWidth > 700 &&
                            <div className="swiper_slide_frame2">
                                <img className="swiper_slide_image" src={require('../../images/parrot.png')} alt="parrot_image" />
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div className="swiper_slide_right">
                        {this.state.screenWidth > 700 &&
                            <div className="swiper_slide_frame2">
                                <img className="swiper_slide_image" style={{ marginLeft: '15%' }} src={require('../../images/duckies.png')} alt="duckie_image" />
                            </div>
                        }
                        <div className="swiper_slide_frame">
                            <div className="swiper_slide_headings_black">
                                <h1 style={{ fontWeight: 'bold' }}>Stand-out in business</h1>
                                {this.state.screenWidth <= 700 &&
                                    <img className="swiper_slide_image" src={require('../../images/duckies.png')} alt="duckie_image" />
                                }
                                <h4 style={{ color: 'grey' }}>Larynx.ai will help you build unique corporate voice brands.</h4>
                            </div>
                            <div className="swiper_slide_buttons">
                                <button className="swiper_button swiper_mainimage_button">Learn More</button>
                                <button className="swiper_mainimage_button swiper_button">Build Your Brand</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="swiper_slide">
                        <div className="swiper_slide_frame">
                            <div className="swiper_slide_headings_black">
                                <h1 style={{ fontWeight: 'bold', }}>Monetize  your voice.</h1>
                                {this.state.screenWidth <= 700 &&
                                    <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                                }
                                <h4 style={{ color: 'grey' }}>Build, use and monetize your voice in Larynx.ai marketplace</h4>
                            </div>
                            <div className="swiper_slide_buttons">
                                <button onClick={() => { this.onMarketPress('buy') }} className="swiper_mainimage_button swiper_button">Buy</button>
                                <button onClick={() => { this.onMarketPress('sell') }} className="swiper_mainimage_button swiper_button">Sell</button>
                            </div>
                        </div>
                        {this.state.screenWidth > 700 &&
                            <div className="swiper_slide_frame2">
                                <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div className="swiper_slide_right">
                        <div className="swiper_slide_frame">
                            <div className="swiper_slide_headings_black">
                                {/* <h3>Users are not listening to your products and services?</h3> */}
                                <h1 style={{ fontWeight: 'bold', }}>Business Apps</h1>
                                {this.state.screenWidth <= 700 &&
                                    <img className="swiper_slide_image" style={{ width: '80%', marginLeft: '-20%' }} src={require('../../images/business.png')} alt="business_image" />
                                }
                                <h4 style={{ color: 'grey' }}>Boost your business with larynx.ai</h4>
                            </div>
                        </div>
                        {this.state.screenWidth > 700 &&
                            <div className="swiper_slide_frame2">
                                <img className="swiper_slide_image" style={{ width: '110%', marginLeft: '-20%' }} src={require('../../images/business.png')} alt="business_image" />
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div className="swiper_slide">
                        <div className="swiper_slide_frame">
                            <div className="swiper_slide_headings_black">
                                <h1 style={{ fontWeight: 'bold' }}> I am Mr.Robot.</h1>
                                {this.state.screenWidth <= 700 &&
                                    <img className="swiper_slide_image" src={require('../../images/robot.png')} alt="robot" />
                                }
                                <h4 style={{ color: 'grey' }}>Larynx.ai helps robots to speak by its own</h4>
                            </div>
                            <div className="swiper_slide_buttons">
                                <button onClick={() => this.onRobotLearnMore()} className="swiper_mainimage_button swiper_button">Learn More</button>
                                <button className="swiper_mainimage_button swiper_button">Buy Voice</button>
                            </div>
                        </div>
                        {this.state.screenWidth > 700 &&
                            <div className="swiper_slide_frame2">
                                <img className="swiper_slide_image" src={require('../../images/robot.png')} alt="robot" />
                            </div>
                        }
                    </div>
                </div>
            </Swiper>
        )
    }
}

export default swiper;