const SETAUTH = "SETAUTH";

const initialState = {
    isAuth:false,
    user:null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SETAUTH: {
            return {
                ...state,
                isAuth: action.isAuth,
                user:action.user
            }
        }
        default: return state
    }
}
