import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GET_TRAIN_START = "GET_TRAIN_START";
const GET_TRAIN_SUCC = "GET_TRAIN_SUCC";
const GET_TRAIN_FAIL = "GET_TRAIN_FAIL";

export default function getTrainData(project,initialCall) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
        if(initialCall){
            trackPromise(
                fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                    method: 'POST',
                    headers: {
                        'gateway':'1',
                        'path':'traincontent/profileId/'+project.project_id+'/languageId/'+project.language_id+'/v2',
                        'method':'GET'
                    }
                })
                    .then((response) => {
                        // if (response.headers.get('content-type').match(/application\/json/)) {
                            return response.json();
                        // }
                        // return response;
                    })
                    .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                    .catch((error) => dispatch(apiCallFail(error)))
            ,'modal_spinner')
        }else{
            fetch(HOST+'/api_handler.php', {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'traincontent/profileId/'+project.project_id+'/languageId/'+project.language_id+'/v2',
                    'method':'GET'
                }
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        }
    }
}

function apiCallStart() {
    return {
        type: GET_TRAIN_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GET_TRAIN_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GET_TRAIN_FAIL,
        error
    }
}
