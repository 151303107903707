
const SETAUTH = "SETAUTH";

export default function setAuthentication(isAuth,user) {
    // console.log(userdata)
    return (dispatch) => {
            dispatch(setAuth(isAuth,user))
    }
}


function setAuth(isAuth,user) {
    return {
        type: SETAUTH,
        isAuth:isAuth,
        user:user
    }
}