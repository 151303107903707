import { createStore,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './data/reducers';

const middleWareArray = [thunk];

if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
  
    middleWareArray.push(logger);
  }

const middleWare = applyMiddleware(...middleWareArray);
const store = createStore(rootReducer,middleWare);

export default store;