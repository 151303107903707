import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GETAUDIT_START = "GETAUDIT_START";
const GETAUDIT_SUCC = "GETAUDIT_SUCC";
const GETAUDIT_FAIL = "GETAUDIT_FAIL";

export default function getAuditData(project,pageno) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'traincontent/audit/project_id/'+project.project_id+'/page_no/'+pageno+'/pagelimit/20',
                    'method':'GET',
                }
            })
                .then((response) => {
                        return response.json();
                })
                .then((jsonresponse) => {
                    console.log(jsonresponse)
                    if(jsonresponse.status){
                        dispatch(apiCallSuccess(jsonresponse.response))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(apiCallFail(error))
                })
        ,'modal_spinner')
    }
}

function apiCallStart() {
    return {
        type: GETAUDIT_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GETAUDIT_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GETAUDIT_FAIL,
        error
    }
}
