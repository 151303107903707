const GET_PREVTRAIN_START = "GET_PREVTRAIN_START";
const GET_PREVTRAIN_SUCC = "GET_PREVTRAIN_SUCC";
const GET_PREVTRAIN_FAIL = "GET_PREVTRAIN_FAIL";

const initialState = {
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PREVTRAIN_START: {
            return {
                ...state,
                isfetching: true,
                data:null,
            }
        }
        case GET_PREVTRAIN_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GET_PREVTRAIN_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
