import React from 'react';
import Swiper from 'react-id-swiper';
// import 'react-id-swiper/lib/styles/css/swiper.css';
import './Customers.css'

import Caption from '../Caption/Caption';


class Customers extends React.Component{
    
    constructor(props){
        super(props);
        this.state={};
    }

    renderCarousal(){
        const params = {
            direction: 'horizontal',
            slidesPerView: 'auto',
            freeMode: true,
            mousewheel: true,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
          }
        return(
            <Swiper {...params}>
                <div className="customer_slide">
                    <img src={require('../../images/larynx_ai2.png')} alt={"slide1"} className="customer_image"/>
                </div>
                <div className="customer_slide">
                    <img src={require('../../images/larynx_ai2.png')} alt={"slide1"} className="customer_image"/>
                </div>
                <div className="customer_slide">
                    <img src={require('../../images/larynx_ai2.png')} alt={"slide1"} className="customer_image"/>
                </div>
                <div className="customer_slide">
                    <img src={require('../../images/larynx_ai2.png')} alt={"slide1"} className="customer_image"/>
                </div>
                <div className="customer_slide">
                    <img src={require('../../images/larynx_ai2.png')} alt={"slide1"} className="customer_image"/>
                </div>
            </Swiper>
        );
    }

    render(){
        return(
            <div className="customers">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Caption title={'Our Customers'} description={'This text is a small description regarding the following topic discussed.'} />
                </div>
                <div>
                    {this.renderCarousal()}
                </div>
            </div>
        );
    }
}

export default Customers;