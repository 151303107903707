import React from 'react';
import {THEME_COLOR} from '../../constants/constants';
import Caption from '../Caption/Caption';
import './Robots.css'

class Robots extends React.Component{

    constructor(props){
        super(props);
        this.state={
            screenWidth:0,
            screenHeight:0,
            dummySteps:[
                {
                    title:'Choose the Voice Model',
                    desc:[
                        'Select a voice model for you business from our business content specific collection.',
                        'Download and deploy the voice package with licence for a specific Robot.',
                    ]
                },
                {
                    title:'Power up your Robot',
                    desc:[
                        'Robot’s voice generation done through the Software Development Kit(SDK).',
                        'The voice model package “embedded larynx or eLarynx” is deployed in your Robot to give human like voice.',
                    ]
                }
            ],
            benefits:[
                {
                    title:'Voice within the Robot',
                    desc:[
                        'Power up your Robot by deploying Larynx.ai Voice Package into Robot infrastructure. Generate interactive audio offline for your Robot.',
                        // 'Generate interactive audio offline for your Robot.',
                    ]
                },
                {
                    title:'No Extra Cost',
                    desc:[
                        'Generate unlimited interactive audio for your Robot. No hidden charges on characters or words. You purchase Specialized licence and enjoy the neural voice for Robots life long.',
                        // 'No hidden charges on characters or words.',
                        // 'You purchase Specialized licence and enjoy the neural voice for Robots life long.',
                    ]
                },
                {
                    title:'Mellifluous Voice',
                    desc:[
                        'Robots interaction made very natural with human like mellifluous voice. You will get the feeling as if you are interacting with fellow human beings.',
                        // 'You will get the feeling as if you are interacting with fellow human beings.',
                    ]
                }
            ],
        }
        window.addEventListener("resize",this.update)
    }

    componentDidMount(){
        this.update();
    }

    update=()=>{
        this.setState((state,props)=>{
            return{
                screenHeight:window.innerHeight,
                screenWidth:window.innerWidth,
            }
        })
    }

    componentWillUnmount(){
        window.removeEventListener("resize",this.update)
    }

    renderBanner(){
        return(
            <div className="swiper_slide">
                <div className="swiper_slide_frame">
                    <div className="swiper_slide_headings_black">
                        <h1 style={{fontWeight:'bold'}}> I am Mr.Robot.</h1>
                        {this.state.screenWidth <= 700 &&
                                <img className="swiper_slide_image" src={require('../../images/robot.png')} alt="robot" />
                        }
                        <h4 style={{color:'grey'}}>Larynx.ai helps robots to speak by its own</h4>
                    </div>
                    <div className="swiper_slide_buttons">
                        <button className="swiper_mainimage_button swiper_button">Buy Voice</button>
                    </div>
                </div>
                {this.state.screenWidth >700 &&
                    <div className="swiper_slide_frame2">
                        <img className="swiper_slide_image" src={require('../../images/robot.png')} alt="robot" />
                    </div>
                }
            </div>
        );
    }

    renderSellSection(){
        return(
            <div style={{background:THEME_COLOR,}} className="robots_section_container">
                <div className="robots_sell_caption">Voice for Robot</div>
                <div className="robots_horizontal_divider"></div>
                <div style={{width:'100%',justifyContent:'space-between'}}>
                    {/* <div className="robots_sell_option">* Human like speaking Robot is the future which will become a reality very soon. </div>
                    <div className="robots_sell_option">* Spoken Language is the convenient interface for communicating with such devices. Thus the making voice user interface is the “In-demand” Technology. </div>
                    <div className="robots_sell_option">* Larynx.ai is the neural voice technology will enable and empower the customers and manufacturers to build, integrate and deploy the same to the Robots.</div> */}
                    <div style={{padding:'0% 1%'}} className="robots_sell_option">Human like speaking Robot is the future which will become a reality very soon. 
                        Spoken Language is the convenient interface for communicating with such devices. Thus the making voice user interface is the “In-demand” Technology.
                        Larynx.ai is the neural voice technology will enable and empower the customers and manufacturers to build, integrate and deploy the same to the Robots.
                    </div>
                </div>
                <div style={{width:'100%',display:'flex'}}>
                    <input placeholder={'Enter your email address...'} style={{width:'40%',outline:'none'}} value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}/>
                    <div className="robots_sell_button">Ping us</div>
                </div>
            </div>
        );
    }

    renderOperatingCard(data){
        return(
            <div className="robots_operating_card_outline">
                <div className="robots_operating_card_imagecontainer">
                    <img 
                    className="robots_operating_card_image"
                    alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                </div>
                <div className="robots_operating_card_textcontainer">
                    <p className="robots_operating_card_title">{data.title}</p>
                    {data.desc.map((desc,index)=>{
                        return(
                            <p key={data.title+','+index} className="robots_operating_card_desc">
                                {desc}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderUseCases(){
        var data = this.state.dummySteps;
        return(
            <div style={{borderTop:'0px solid grey',paddingTop:'1%',}}>
                <div style={{paddingBottom:'0%'}} className="robots_section_container">
                    <div style={{color:'black'}} className="robots_sell_caption">Operating Principle</div>
                    <div className="robots_horizontal_divider"></div>
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img className="robots_flowdigram_image" src={require('../../images/how_it_works.jpg')} alt={'how_it_works'}/>
                    </div>
                </div>
                <div className="robots_operating_container">
                    {this.renderOperatingCard(data[0])}
                    {this.renderOperatingCard(data[1])}
                </div>
            </div>
        );
    }

    renderBenefit(image,benefit) {
        return (
            <div className="robots_benefit_container">
                <div className="robots_benefit_info_container">
                    <img className="robots_benefit_image" src={image} alt={'benefit'} />
                    <p id="robots_benefit_title">{benefit.title}</p>
                </div>
                <div id="robots_benefit_desc">
                    {
                        benefit.desc.map((text,index)=>{
                            return(
                                <div key={'robots_benefits_'+index} className="robots_benefits_option">{text}</div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    renderSeperator(){
        return(
            <div className="robots_benefit_sepearator"></div>
        );
    }

    renderBenefits(){
        return(
            <div style={{background:'white',paddingTop:'0%'}} className="robots_section_container">
                <div style={{color:'black'}} className="robots_sell_caption">Benefits</div>
                <div className="robots_horizontal_divider"></div>
                <div className="robots_benefits_container">
                    {this.renderBenefit(require('../../images/secure.jpeg'),this.state.benefits[0])}
                    {this.renderSeperator()}
                    {this.renderBenefit(require('../../images/available.jpeg'),this.state.benefits[1])}
                    {this.renderSeperator()}
                    {this.renderBenefit(require('../../images/scalable.jpeg'),this.state.benefits[2])}
                </div>
            </div>
        );
    }

    renderLearnMore(){
        return(
            <div style={{background:'#2E207C',}} className="buy_section_container">
                <div className="buy_sell_caption">Explore More</div>
                <div className="buy_horizontal_divider"></div>
                <div style={{width:'100%',justifyContent:'space-between'}}>
                    <div className="buy_sell_option">Interested to give human like voice to your Robots. Please provide us your contact details. We will contact you promptly and answer all your product specific queries.</div>
                </div>
                <div style={{width:'100%',display:'flex'}}>
                    <input placeholder={'Enter your email address...'} style={{width:'40%',outline:'none'}} value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}/>
                    <div className="buy_sell_button">Show Interest</div>
                </div>
        </div>
        );
    }

    

    render(){
        return(
            <div className="robots">
                {this.renderBanner()}
                {this.renderSellSection()}
                {this.renderUseCases()}
                {this.renderBenefits()}
                {this.renderLearnMore()}
            </div>
        );
    }
}

export default Robots;