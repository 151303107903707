
const UPDATE_BLIPS = "UPDATE_BLIPS";

export default function updateBlips(blips) {
    return (dispatch) => {
        dispatch(update(blips))
    }
}


function update(data) {
    return {
        type: UPDATE_BLIPS,
        data,
    }
}

