const GET_BLIPS_START = "GET_BLIPS_START";
const GET_BLIPS_SUCC = "GET_BLIPS_SUCC";
const GET_BLIPS_FAIL = "GET_BLIPS_FAIL";
const UPDATE_BLIPS = "UPDATE_BLIPS";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BLIPS_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case GET_BLIPS_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case UPDATE_BLIPS: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GET_BLIPS_FAIL: {
            return {
                ...state,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
