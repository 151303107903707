import React from 'react';
import Caption from '../Caption/Caption';
import './CelebrityVoice.css';
import Swiper from 'react-id-swiper';
import data from './sample.json';
import {Modal} from 'react-bootstrap';

class CelebrityVoice extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:data,
            showModal:false,
            selected_celebrity:null,
            selected_celebrity_img:null
        }
    }

    renderModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showModal} onHide={()=>this.handleLoginModal()}>
                    <div className="cel_modal_outline">
                        <div className="cel_modal_img_container">
                            <img className="cel_modal_image" src={this.state.selected_celebrity_img} alt={this.state.selected_celebrity.name}/>
                        </div>
                        <div className="cel_modal_infocontainer">
                            <p id="cel_modal_title">{this.state.selected_celebrity.name}</p>
                            <p id="cel_modal_occupation">{this.state.selected_celebrity.occupation}</p>
                            <p id="cel_modal_desc">{this.state.selected_celebrity.description}</p>
                        </div>
                    </div>
            </Modal>
        );
    }

    handleLoginModal(){
        this.setState((state,props)=>{
            return{
                showModal:false
            }
        })
    }

    onCelebrityClick(celebrity,image){
        this.setState((state,props)=>{
            return{
                selected_celebrity:celebrity,
                selected_celebrity_img:image,
                showModal:true
            }
        })
    }

    renderCards(celebrity,image){
        return(
            <div className="celebrity_card_outline">
                <div style={{display:'flex',width:'100%',height:'100%',}}>
                    <div id="celebrity_card_image_container">
                        <img onClick={()=>this.onCelebrityClick(celebrity,image)} src={image} alt={'card avatar'} id="cel_card_avatar_image"/>
                    </div>
                    <div className="celebrity_infocontainer">
                        <div id="cel_card_text_div">
                            <p className="cel_name">{celebrity.name}</p>
                            <p className="cel_job">{celebrity.occupation}</p>
                        </div>
                        <audio id="cel_audiotag" ref="audio_tag" src="./static/music/foo.mp3" controls/>
                    </div>
                </div>
            </div>
        );
    };

    render(){
        const params = {
            loop:true,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
          }
        return(
            <div className="celebrityvoice">
                {this.state.selected_celebrity && this.renderModal()}
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption title={'Celebrity Voice'} description={'To all the fanboys / fangirls out there do check and browse celebrity of your choice. Our neural voice will power up your devices and apps with their voice :)'}/>
                    
                </div>
                <div className="applications_swiper_container">
                    <Swiper {...params}>
                        <div id="celebrity_slide_container">
                            {this.renderCards(data[0],require('../../images/aron_rodgers.jpg'))}
                            {this.renderCards(data[1],require('../../images/steve_jobs.jpg'))}
                            {this.renderCards(data[2],require('../../images/will_smith.jpg'))}
                        </div>
                        <div id="celebrity_slide_container">
                            {this.renderCards(data[3],require('../../images/barack_obama.jpg'))}
                            {this.renderCards(data[4],require('../../images/oprah_winfrey.jpg'))}
                            {this.renderCards(data[5],require('../../images/aron_rodgers.jpg'))}
                        </div>
                    </Swiper>
                </div>
            </div>
        );
    }
}

export default CelebrityVoice;