import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GETCREATEDETAILS_START = "GETCREATEDETAILS_START";
const GETCREATEDETAILS_SUCC = "GETCREATEDETAILS_SUCC";
const GETCREATEDETAILS_FAIL = "GETCREATEDETAILS_FAIL";

export default function getCreateDetails(userdata) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'profile/'+userdata.id+'/languages/view',
                    'method':'GET',
                    'token':userdata.token
                }
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        )
    }
}

function apiCallStart() {
    return {
        type: GETCREATEDETAILS_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GETCREATEDETAILS_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GETCREATEDETAILS_FAIL,
        error
    }
}
