import React from 'react';
import './Audit.css'
import Fade from 'react-reveal/Fade';
import {Modal} from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { trackPromise} from 'react-promise-tracker';
import Loader from 'react-loader-spinner'


//connect to Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getAuditData from '../../data/actions/getAuditData'
import S3 from 'aws-s3';
import {HOST} from '../../constants/constants'

const MicRecorder = require('mic-recorder-to-mp3');

const config = {
    bucketName: 'larynx-webdata-v2',
    dirName: 'uploads', /* optional */
    region: 'us-east-2',
    accessKeyId: 'AKIAYLJ64NZTK2HFDOVY',
    secretAccessKey: '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',
}

const ReactS3Client = new S3(config);

const recorder = new MicRecorder({
    bitRate: 320
});

//mapping state
function mapStateToProps(state) {
    return {
        GETAUDIT: state.getAuditDataReducer
    }
}
//mapping props
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAuditData
    }, dispatch);
}

class Audit extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showEmpty:true,
            audioduration:0,
            isRecording:false,
            isUploading:false,
            showAlert:false,
            dummyData:[
                {
                    index:1,
                    title:'Mrs. Evelyn D Siegel, a social worker who interviewed both Lee and his mother while Lee was confined in Youth House,',
                    status:'Sucess',
                    voice:'Elijah',
                },
                {
                    index:2,
                    title:'Mrs. Evelyn D Siegel, a social worker who interviewed both Lee and his mother while Lee was confined in Youth House,',
                    status:'Sucess',
                    voice:'Elijah',
                },
                {
                    index:3,
                    title:'Mrs. Evelyn D Siegel, a social worker who interviewed both Lee and his mother while Lee was confined in Youth House,',
                    status:'Sucess',
                    voice:'Elijah',
                },
                {
                    index:4,
                    title:'Mrs. Evelyn D Siegel, a social worker who interviewed both Lee and his mother while Lee was confined in Youth House,',
                    status:'Sucess',
                    voice:'Elijah',
                },
                {
                    index:5,
                    title:'Mrs. Evelyn D Siegel, a social worker who interviewed both Lee and his mother while Lee was confined in Youth House,',
                    status:'Sucess',
                    voice:'Elijah',
                },
            ],
            auditData:[],
            userdata:JSON.parse(localStorage.getItem('user_data')),
            selectedAudio:null,
            playerModal:false,
            recordModal:false,
            totalPageCount:[],
            pageCount:[],
            selectedPageCount:0,
        };
    }

    componentDidMount(){
        console.log(this.props)
        if(this.props.location && this.props.location.state && this.props.location.state){
            this.props.getAuditData(this.props.location.state.project,this.state.selectedPageCount);
            this.setState((state,props)=>{
                return{
                    project:props.location.state.project
                }
            })
        }
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        if(nextprops.GETAUDIT.data && nextprops.GETAUDIT.data != prevstate.auditData){
            var totalpages = [];
            var pages=[];
            if(prevstate.pageCount.length===0 && nextprops.GETAUDIT.data.length > 0){
                for(var i = 0; i<= nextprops.GETAUDIT.data[0].page_count;i++){
                    totalpages.push(i);
                    if(i <10){
                        pages.push(i)
                    }
                }
            }
            return{
                auditData:nextprops.GETAUDIT.data,
                totalPageCount:(prevstate.pageCount.length===0)? totalpages : prevstate.totalPageCount,
                pageCount:(prevstate.pageCount.length===0)?pages : prevstate.pageCount,
                showEmpty:(nextprops.GETAUDIT.data.length > 0)? false : true
            }
        }

        return null;
    }

    handleAlertModal(){
        if(this.state.showAlert){
            this.setState((state,props)=>{
                return {showAlert:false};
            });
        }
    }

    renderAlertModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showAlert} onHide={()=>this.handleAlertModal()}>
                <div style={{fontWeight:'bold',display:'flex',flexDirection:'column',alignItems:'center',}} className="voices_pie_modal">
                    <div style={{marginBottom:'10px',fontSize:'20px'}}>The Audio seems to be too Long.... Please Re-record</div>
                    <div onClick={()=>this.handleAlertModal()} className="blip_button">OK</div>
                </div>
            </Modal>
        );
    }

    startRecording(){
        recorder.start().then(() => {
            // something else
            this.setState((state,props)=>{
                return{
                    isRecording:true,
                    audioduration:new Date().getTime(),
                }
            })
          }).catch((e) => {
            console.error(e);
          });
    }

    stopRecording(content){
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, 'me-at-thevoice.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });
                if(new Date().getTime() - this.state.audioduration > 30000){
                    this.setState((state,props)=>{
                        return{
                            showAlert:true
                        }
                    })
                }else{
                    this.onUpload(file,content)
                    this.reportUpload(content)
                    this.setState((state,props)=>{
                        return{
                            isUploading:true,
                        }
                    })
                }
                // player.play();
                this.setState((state,props)=>{
                    return{
                        isRecording:false,
                        audioduration:new Date().getTime() - state.audioduration,
                    }
                })
            }).catch((e) => {
                alert('We could not retrieve your message');
                console.log(e);
            });
    }

    async onUpload(file,content) {
        var name = this.state.project.project_id+'/'+content.ljId
        trackPromise(
            ReactS3Client.uploadFile(file, name)
                .then(data => {
                    console.log('successful')
                })
                .catch(err => console.error(err))
        ,'modal_spinner')
    }
    
    reportUpload(content){
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'traindata',
                    'method':'POST'
                },
                body: JSON.stringify({
                    'fk_language_id': this.state.project.language_id,
                    'fk_lj_id': content.ljId,
                    'fk_profile_id': this.state.project.project_id,
                    'fk_lj_order_id': content.orderId,
                    's3_url': 'https://larynx-webdata-v2.s3-us-east-2.amazonaws.com/uploads/'+this.state.project.project_id+'/'+content.ljId+".mp3",
                    'is_varified': 0,

                }),
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => {
                    console.log('reported upload .. ')
                    this.setState((state,props)=>{
                        return{
                            isUploading:false,
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        ,'modal_spinner');
    }

    handlePlayerModal(audio){
        var tempaudio = null;
        if(this.state.selectedAudio == null){
            console.log(audio)
            tempaudio=audio
        }
        this.setState((state,props)=>{
            return{
                playerModal:!state.playerModal,
                selectedAudio:tempaudio
            }
        })
    }

    renderPlayerModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                show={this.state.playerModal}>
                <div style={{outline:'none'}} className="voices_pie_modal">
                    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',padding:'0% 0% 2% 0%'}}>
                        <div onClick={()=>this.handlePlayerModal({})} style={{alignSelf:'right',color:'blue',cursor:'pointer'}}>close</div>
                    </div>
                    <AudioPlayer
                        autoPlay
                        src={(this.state.selectedAudio)? this.state.selectedAudio.s3_url+'?random = '+new Date().getTime() : ""}
                        onPlay={e => console.log("onPlay")}
                        // other props here
                    />
                </div>
            </Modal>
        );
    }

    renderEmpty(){
        return(
            <div className="audit_emptycontainer">
                    <h1>No Recordings Found</h1>
                    <h4>You have no recordings. Use voice screen to start <h4 style={{fontWeight:'bold',marginLeft:'10px'}}>recording</h4></h4>
            </div>
        );
    }

    handleRecordModal(audio){
        console.log(audio)
        this.setState((state,props)=>{
            return{
                recordModal:!state.recordModal,
                selectedAudio:audio
            }
        })
    }

    renderRecordModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                show={this.state.recordModal}>
                <div style={{outline:'none'}} className="voices_pie_modal">
                    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',padding:'0% 0% 2% 0%'}}>
                        <div onClick={()=>this.handleRecordModal(null)} style={{alignSelf:'right',color:'blue',cursor:'pointer'}}>close</div>
                    </div>
                    <div className="audit_recordmodal_textbox">
                        <p style={{fontSize:'200%',textAlign:'center'}}>{this.state.selectedAudio.text}</p>
                    </div>
                    {this.state.isRecording &&
                        <div style={{marginTop:'5%',marginLeft:'-40%'}} className="voices_section3">
                            <img style={{width:'70%',marginRight:'-40%'}} src={require('../../images/waveform.gif')} alt={'waveform'}/>
                            <div style={{}} onClick={()=>{this.stopRecording(this.state.selectedAudio)}} className="voices_section3_button">
                                <img style={{height:'35%',}} src={require('../../images/stop_orange.png')} alt="mike"/>
                            </div>
                        </div>
                    }
                    {!this.state.isRecording &&
                        <div style={{marginTop:'5%'}} className="voices_section3">
                            <div style={{pointerEvents:(this.state.isUploading)? 'none' : 'auto',marginLeft:'2%'}} onClick={()=>this.startRecording()} className="voices_section3_button">
                                {!this.state.isUploading && <img style={{height:'35%'}} src={require('../../images/microphone_branch.png')} alt="mike"/>}
                                {this.state.isUploading && <Loader type="Bars" color="#30217F" width={30} height={30}/>}
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        );
    }

    renderBlips(){
        var blipcount = this.state.auditData.length;
        return(
            <div className="audit_blipscontainer">
                <div className="audit_blipHeadRow">
                    <div className="headercaption">No</div>
                    <div style={{width:'70%',paddingLeft:'1%'}} className="headercaption">Title</div>
                    <div className="headercaption headercaption_filler">sample</div>
                    <div className="headercaption headercaption_filler">sample</div>
                </div>
                <div className="audit_blipdatarow">
                    {/* <Fade bottom cascade> */}
                    {this.state.auditData.length > 0 && this.state.auditData.map((blip,index)=>{
                        return(
                            <div key={blip.title+','+index} className={(index !== blipcount-1)? "audit_blipRowcontainer_border" : "audit_blipRowcontainer"}>
                                <div style={{marginRight:'5%',marginLeft:'1%'}}>{blip.orderId}</div>
                                <div className="audit_bliptextdata">{blip.text}</div>
                                <div className="audit_blipdata headercaption_filler">
                                    <div onClick={()=>this.handlePlayerModal(blip)} className={"audit_playbutton"} style={{cursor:'pointer',width:'30%',}}>
                                        <img style={{width:'75%'}} src={require('../../images/play.png')} alt="play_image"/>
                                    </div>
                                </div>
                                <div className="audit_blipdata headercaption_filler">
                                    <div onClick={()=>this.handleRecordModal(blip)} className={"audit_playbutton"} style={{cursor:'pointer',width:'30%',}}>
                                        <img style={{width:'80%'}} src={require('../../images/microphone_branch.png')} alt="play_image"/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {/* </Fade> */}
                </div>
            </div>
        );
    }

    onPageLinkPress(count){
        console.log(count)
        this.props.getAuditData(this.state.project,count);
        var selectedPageCountIndex = this.state.totalPageCount.indexOf(count);
        var totalCountIndex = this.state.totalPageCount.length - 1;
        // console.log(selectedPageCountIndex)
        // console.log(totalCountIndex)
        var tempPageCount = [];
        if(selectedPageCountIndex > 4){
            if(totalCountIndex-selectedPageCountIndex > 4){
                // console.log('here 1')
                for(var i = selectedPageCountIndex-4; i <= selectedPageCountIndex+5;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }else{
                // console.log('here 2')
                for(var i = totalCountIndex-8; i <=totalCountIndex;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }
        }else{
            if(totalCountIndex >9){
                for(var i = 0; i <10;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }else{
                for(var i = 0; i <=totalCountIndex;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }
        }
        // console.log(tempPageCount)
        this.setState((state,props)=>{
            return{
                selectedPageCount:count,
                pageCount:tempPageCount
            }
        })
    }


    renderBottomNavigation(){
        return(
            <div className={"audit_bottomcontainer"}>
                {this.state.selectedPageCount > 0 && <div onClick={()=>this.onPageLinkPress(this.state.selectedPageCount-1)} className="audit_pagelink">{'<< Previous'}</div>}
                {this.state.pageCount.length > 0 && 
                    this.state.pageCount.map((count,index)=>{
                        return(
                            <div onClick={()=>{this.onPageLinkPress(count)}} className={(this.state.selectedPageCount == count)? "selected_audit_pagelink":"audit_pagelink"} key={'pageCount'+count}>{count+1}</div>
                        );
                    })
                }
                {this.state.totalPageCount[this.state.totalPageCount.length-1] !== this.state.selectedPageCount &&<div onClick={()=>this.onPageLinkPress(this.state.selectedPageCount+1)}  className="audit_pagelink" >{'Next >>'}</div>}
            </div>
        );
    }

    render(){
        return(
            <div className="audit_container">
                {this.renderPlayerModal()}
                {this.renderAlertModal()}
                {this.state.selectedAudio && this.renderRecordModal()}
                <div className="audit_headingdiv">
                    <Fade timeout={1000} top>
                        <div style={{width:'100%',display:'flex',alignItems:'flex-end',}}>
                            <div style={{display:'flex',fontWeight:'500',color:'#F3913D',fontSize:'35px'}}>{this.state.project && this.state.project.project_name} <div className="audit_mainHeading"> - recordings</div></div>
                            {this.state.project && <div style={{marginBottom:'1%',marginLeft:'2%'}}>{this.state.project.language+' - '+this.state.project.style}</div>}
                        </div>
                    </Fade>
                </div>
                <div className="audit_contentdiv">
                    {this.state.showEmpty && <p className="audit_blipstitle">Your recordings</p>}
                    <div className="audit_blips_ListingDiv">
                        {this.state.showEmpty && this.renderEmpty()}
                        {!this.state.showEmpty && this.renderBlips()}
                    </div>
                </div>
                {!this.state.showEmpty && this.renderBottomNavigation()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Audit);