// import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const REGISTER_USER_START = "REGISTER_USER_START";
const REGISTER_USER_SUCC = "REGISTER_USER_SUCC";
const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export default function userRegistration(username,email,category,password) {
    return (dispatch) => {
        dispatch(apiCallStart())
        // trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'registration',
                    'method':'POST'
                },
                body: JSON.stringify({
                    "profile_name":username,
                    "nick_name":"dummy",
                    "profile_pic_url":"dummy",
                    "proxy_dp_url":"dummy",
                    "customer_category_id":category,
                    "email":email,
                    "authid":"1",
                    "is_house_pwd":1,
                    "password_hash":password,
                    "provider_id":"1"
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        // )
    }
}

function apiCallStart() {
    return {
        type: REGISTER_USER_START
    }
}

function apiCallSuccess(data) {
    return {
        type: REGISTER_USER_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: REGISTER_USER_FAIL,
        error
    }
}
