import React from 'react';
import Caption from '../Caption/Caption';
import Fade from 'react-reveal/Fade';

class Sell extends React.Component{

    constructor(props){
        super(props);
        this.state={
            screenHeight:0,
            screenWidth:0,
            dummySteps:[
                {
                    title:'Build your neural voice',
                    desc:['Work and earn from the comforts of your home by reading a book to train our Artificial Intelligence (AI) engine.']
                },
                {
                    title:'Publish your profile',
                    desc:[
                        'Train, record, and review your neural voice for best quality. Decide the price, terms and conditions for your voice. Larynx.ai will review and publish your voice in multiple channels.'
                    //     'Train, record, and review your neural voice for best quality.',
                    //     'Decide the price, terms and conditions for your voice.',
                    //     'Larynx.ai will review and publish your voice in multiple channels.',
                    ]
                },
                {
                    title:'Market your voice',
                    desc:[
                        'Larynx.ai will market your voice brand by integrating it to various voice enabled devices and applications. Increase the visibility of your voice by sharing.',
                    ],
                },
                {
                    title:'Review audio projects',
                    desc:[
                        'Select the audio project of your choice or appoint us to service you for reviewing the projects for your selection.',
                    ],
                },
                {
                    title:'Get paid',
                    desc:[
                        'Larynx.ai will provide you voice usage analytic reports for getting paid. Select  the payment duration of your choice (One Time / Periodic).',
                    ]
                }
            ],
        }
        window.addEventListener("resize",this.update);
    }

    componentDidMount(){
        this.update();
    }

    update=()=>{
        this.setState((state,props)=>{
            return{
                screenHeight:window.innerHeight,
                screenWidth:window.innerWidth
            }
        })
    }

    componentWillUnmount(){
        window.removeEventListener("resize",this.update)
    }

    renderHowItWorks(){
        return(
            <div className="sell_whatismarket_container">
                <div className="corporate_howitworks_title_container">
                    <p className="corporate_howitworks_title">What is Larynx.ai market ?</p>
                </div>
                <div className="corporate_howitworks_desc_container">
                    <p>
                        Early in the market means more opportunities.
                    </p>
                    <p>
                        Larynx will provide you more venues to sell your voice with less effort.
                    </p>
                    <p>
                        Join now Beat the crowd and earn more.
                    </p>
                    <p>
                        Larynx will help you to become popular
                    </p>
                </div>
            </div>
        );
    }

    renderLeftImageUseCase(data){
        return(
            <div className="cv_usecase_outline">
                <div className="cv_usecase_imagecontainer">
                    <img 
                    className="cv_usecase_image"
                    alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                </div>
                <div className="cv_usecase_separator"></div>
                <div className="cv_usecase_infocontainer">
                    <p className="cv_usecase_title">{data.title}</p>
                    {data.desc.map((desc,index)=>{
                        return(
                            <p key={data.title+','+index} className="cv_usecase_desc">
                                {desc}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderRightImageUseCase(data){
        return(
            <div className="cv_usecase_outline">
                <div className="cv_usecase_infocontainer">
                    <p style={{textAlign:'left'}} className="cv_usecaseright_title">{data.title}</p>
                    {data.desc.map((desc,index)=>{
                        return(
                            <p key={data.title+','+index} className="cv_usecaseright_desc">
                                {desc}
                            </p>
                        );
                    })}
                </div>
                <div className="cv_usecase_separator"></div>
                <div className="cv_usecase_imagecontainer">
                    <img 
                    className="cv_usecase_image"
                    alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                </div>
            </div>
        );
    }

    renderUseCases(){
        var data = this.state.dummySteps;
        return(
            <div style={{borderTop:'0px solid grey',paddingTop:'1%',borderBottom:'1px solid grey'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption title={'Its Easy'} 
                    description={'What do you want to do? It is simple to go through the following'}/> 
                </div>
                <div className="cv_usecases_container">
                    {this.renderLeftImageUseCase(data[0])}
                    {this.renderRightImageUseCase(data[1])}
                    {this.renderLeftImageUseCase(data[2])}
                    {this.renderRightImageUseCase(data[3])}
                    {this.renderLeftImageUseCase(data[4])}
                </div>
            </div>
        );
    }

    onMarketPress(type){
        this.props.history.push({
            pathname:'/market',
            state: { type: type }
        });
    };
    
    renderBanner(){
        return(
            <div className="swiper_slide">
                <div className="swiper_slide_frame">
                    <div className="swiper_slide_headings_black">
                        <h1 style={{fontWeight:'bold',}}>Monetize  your voice.</h1>
                        {this.state.screenWidth <= 700 &&
                                <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                        }
                        <h4 style={{color:'grey'}}>Build, use and monetize your voice in Larynx.ai marketplace</h4>
                    </div>
                    <div className="swiper_slide_buttons">
                        <button onClick={()=>{this.onMarketPress('buy')}} className="swiper_mainimage_button swiper_button">Buy</button>
                        <button className="swiper_mainimage_button_selected swiper_button">sell</button>
                    </div>
                </div>
                {this.state.screenWidth >700 &&
                    <div className="swiper_slide_frame2">
                        <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                    </div>
                }
            </div>
        );
    }

    render(){
        return(
            <div className="sell">
                {/* <div className="mainimage_container">
                    <div className="swiper_slide_frame2">
                        <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="duckies" />
                    </div>
                    <div className="swiper_slide_frame">
                        <div className="swiper_slide_headings_black">
                            <h1 style={{color:'black',fontWeight:'bold',fontSize:'3rem'}}>Sample Heading</h1>
                        </div>
                    </div>
                </div> */}
                {this.renderBanner()}
                <Fade timeout={1000} bottom>
                    <div>
                        {this.renderHowItWorks()}
                        {this.renderUseCases()}
                    </div>
                </Fade>
            </div>
        );
    }
}

export default Sell;