import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ProtectedRoute from './Protected/ProtectedRoute';


//store
import {Provider} from 'react-redux';
import store from './store';
//////


//Common Components
import LoadingSpinner from './components/Loading/Loading'
import ModalSpinner from './components/Loading/ModalLoading'
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import './App.css';

/// Screens
import Home from './components/Home/Home';
import CreateProject from './components/Projects/Create/Create';
import Projects from './components/Projects/Projects/Projects';
import Voices from './components/Voices/Voices';
import Editor from './components/Editor/Editor';
import Login from './components/Login/Login';
import CorporateVoice from './components/CorporateVoice/CorporateVoice';
import Project from './components/Projects/Project/Project';
import Blip from './components/Projects/Blip/Blip';
import Market from './components/Market/Market';
import Robots from './components/Robots/Robots';
import Audit from './components/Audit/Audit'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.getPhpData();
    document.title = 'Larynx.ai';
    console.log(localStorage.getItem('session_time'));
    // console.log(window.location.origin);
  };


  getPhpData(){
    console.log('php data')
    fetch('http://larynx.ai/api/tutorial.php')
     .then(response => {
       return response.json()
      })
     .then(json => {
          console.log(json)
     })
  }

  drawerToggleClickHandler = () => {
    this.setState((prevstate) => {
      return {
        sideDrawerOpen: !prevstate.sideDrawerOpen
      };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  };

  render() {
    return (
      <div className="App" style={{ height: '100%' }}>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler} />
        {this.state.sideDrawerOpen && <Backdrop click={this.backdropClickHandler} />}
        <LoadingSpinner width="300" height="100"/>
        <ModalSpinner area="modal_spinner" width="300" height="100"/>
          <main style={{ marginTop: '60px', padding: '0', width: '100%', height: '100%' }}>
            <Route path="/" exact component={Home} />
            <ProtectedRoute path="/createprojects" component={CreateProject} />
            <ProtectedRoute path="/projects" component={Projects} />
            <ProtectedRoute path="/project" component={Project} />
            <ProtectedRoute path="/blip" component={Blip}/>
            <ProtectedRoute path="/voices" component={Voices} />
            <ProtectedRoute path="/audit" component={Audit} />
            <Route path="/market" component={Market} />
            <Route path="/robots" component={Robots}/>
            <Route path="/login" component={Login} />
            <Route path="/corporatevoice" component={CorporateVoice}/>
            <Route path="/editor" component={Editor}/>
          </main>
      </div>
    );
  }
}

function AppRouter() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default AppRouter;
