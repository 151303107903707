import React from 'react';
import './DrawerToggleButton.css';

const drawerToggleButton=props=>(
    <button onClick={props.click} className="toggle_button">
        <div className="toggle_button_line"></div>
        <div className="toggle_button_line"></div>
        <div className="toggle_button_line"></div>
    </button>
);

export default drawerToggleButton;