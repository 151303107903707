import React from 'react';
import './Projects.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Expiry from '../../ExpiryModal/Expired';

import S3 from 'aws-s3';

//connect to Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import listProjects from '../../../data/actions/ListProjects'

//mapping state
function mapStateToProps(state) {
    return {
        LISTPROJECTS: state.listProjectsReducer
    }
}
//mapping props
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listProjects
    }, dispatch);
}


var documentnow;


const config = {
    bucketName: 'zd-audiobook-imgstore',
    dirName: 'document/upload', /* optional */
    region: 'us-west-2',
    accessKeyId: 'AKIAIO2F3DR7JHJADSPQ',
    secretAccessKey: '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',
}

const S3Client = new S3(config);

class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEmpty:true,
            userdata:JSON.parse(localStorage.getItem('user_data')),
            expiryModal:false,
        }
    }

    componentDidMount() {
        console.log(this.state.userdata)
        this.props.listProjects(this.state.userdata)
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        if (nextprops.LISTPROJECTS.data && !nextprops.LISTPROJECTS.data.status) {
            if(nextprops.LISTPROJECTS.data.length === 0){
                return{
                    showEmpty:true
                }
            }else if(nextprops.LISTPROJECTS.data[0] && nextprops.LISTPROJECTS.data[0].token == 'expired' && nextprops.LISTPROJECTS.data[0].status == null){
                return {
                    expiryModal:true,
                }
            }else{
                return {
                    showEmpty:false,
                    isfetching: nextprops.LISTPROJECTS.isfetching,
                    data: nextprops.LISTPROJECTS.data
                }
            }
        }else if(nextprops.LISTPROJECTS.error && nextprops.LISTPROJECTS.error.status){
            return {
                expiryModal:true,
                apiStatus:nextprops.LISTPROJECTS.error
            }
        }else {
            return null;
        }
    }

    renderEmpty(){
        return(
            <div className="projects_emptycontainer">
                    <h1>No Projects Found</h1>
                    <h4>You have no projects. Create a new one and start using <h4 style={{fontWeight:'bold',marginLeft:'10px'}}>Larynx.ai</h4></h4>
            </div>
        );
    }

    onProjectSelect(project){
        var pathName = 'voices';
        if(this.state.userdata.customer_category_id == 1){
            pathName = 'project';
        }
        this.props.history.push({
            pathname:'/'+pathName,
            state: { project: project }
        });
    }

    getPercentage(statuses,current_status){
        var currentIndex = statuses.indexOf(current_status)+1;
        var percentage = ((currentIndex)/statuses.length)*100;
        return percentage;
    }

    renderProjects(){
        return(
            <Fade bottom cascade>
            <div className="projetcspage_projects_container">
                {this.state.data.map((project,index)=>{
                    var status_color = (project.current_status === 'completed')? 'green' : '#2E207C';
                    var status_image = (project.current_status === 'completed')? require('../../../images/check.png') : require('../../../images/hour-glass.png');
                    return(
                        <div key={project.project_name+','+index} className="projectspage_project_container">
                            <div style={{padding:'1% 0%',alignItems:'center',width:'100%',display:'flex',justifyContent:'space-between'}}>
                                <p style={{fontWeight:'bold',color:'#000000',width:'50%',textTransform:'uppercase'}}>{project.project_name}</p>
                                <div style={{width:'50%',display:'flex',justifyContent:'flex-end',}}>
                                    <p style={{color:status_color}} className="projectspage_project_statustext">{project.current_status}</p>
                                    <img style={{width:'10%',height:'10%',margin:'0% 1% 0% 1%'}} src={status_image} alt={'status_image'}/>
                                </div>
                            </div>
                            <div className="projectspage_project_infocontainer">
                                <div style={{display:'flex',width:'100%',margin:'2% 0% 4% 0%'}}>
                                    <p className="projectspage_project_smalltext">{project.description}</p>
                                    {(project.current_status === 'pending') ? 
                                        <div></div>
                                        :
                                        <div style={{pointerEvents:(project.current_status === 'pending')? 'none' : 'auto'}} onClick={()=>this.onProjectSelect(project)} className="projects_project_button">{project.next_status}</div>
                                    }
                                    </div>
                                {/* <div style={{display:'flex',}}> */}
                                    {/* <p className="project_smallcaption">Description : </p> */}
                                    {/* <p className="projectspage_project_smalltext">{project.description}</p>
                                </div> */}
                                {/* <div style={{display:'flex'}}>
                                    <p className="project_smallcaption">status : </p>
                                    <p className="project_smalltext project_statustext">{project.status}</p>
                                </div> */}
                            <div>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',}}>
                                <ProgressBar
                                    height={5}
                                    width={'90%'}
                                    filledBackground="#54B749"
                                    percent={this.getPercentage(project.status,project.current_status)}>
                                        {project.status && project.status.map((status,index)=>{
                                            return(
                                                <Step key={project.project_name+','+status+'index'} transition="scale">
                                                    {({ accomplished, index }) => (
                                                    <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                                                        
                                                    </div>
                                                    )}
                                                </Step>
                                            );
                                        })}
                                </ProgressBar>
                                <div style={{display:'flex',width:'99%',marginTop:'10px',justifyContent:'space-between'}}>
                                    {project.status && project.status.map((status,index)=>{
                                        return(
                                            <p key={'prog'+index} style={{fontSize:'80%',color:'grey'}}>{status}</p>
                                        );
                                    })}
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div></Fade>
        );
    }

    render() {
        return (
            <div className="projects_MainStyle">
                <Expiry showModal={this.state.expiryModal}/>
                <div className="projectspage_Headingpart">
                    <Fade timeout={1000} top>
                        <p className="projectspage_mainHeading">VOICE PROJECTS</p>
                        <Link to="/createprojects" style={{ textDecoration: 'none' }}>
                            <div className="projectspage_createnew">
                                NEW PROJECT
                            </div>
                        </Link>
                    </Fade>
                </div>
                <div className="projectspage_secondSectionmain">
                    {!this.state.showEmpty && <p className="projectpage_YourProjects">Your Projects</p>}
                    <div className="projectspage_allprojectsListingDiv">
                        {this.state.showEmpty && this.renderEmpty()}
                        {!this.state.showEmpty && this.state.data && this.state.data.length > 0 && this.renderProjects()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);