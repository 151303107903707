import React from 'react';
import './HomeBenefits.css';

import Caption from '../Caption/Caption';

class HomeBenefits extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            desc:[
                'Guaranteed end to end data protection with secured Machine Learning platform. Larynx.ai uses Artificial Intelligence and Manual Audit for security, integrity, confidentiality, and risk assessment.',

                'Larynx.ai deployed and replicated on different Amazon Web Service( AWS) regions makes it 99.99% available. Very resilient, since it can withstand critical failure and recover in an acceptable predefined manner and duration.',

                'Based on Customer’s needs and usages,  scale up / down  the compute capability with minimal cost. Larynx.ai Voice platform provides Transparent, Steadfast performance, and Easy Upgrade with no down time.',

                'Do you have Cloud or on-premise infrastructure or Robot? Larynx.ai is flexible to adapt and integrate using it’s Application Programming Interface (API) and Software Development Kit (SDK) to any given environment of the Customers'
            ],
        }
    }

    renderBenefit(title,image,desc) {
        return (
            <div className="benefit_container">
                <div className="benefit_info_container">
                    <img className="benefit_image" src={image} alt={'benefit'} />
                    <p id="benefit_title">{title}</p>
                </div>
                <div id="benefit_desc">
                    {desc}
                </div>
            </div>
        );
    }

    renderSeperator(){
        return(
            <div className="benefit_sepearator"></div>
        );
    }

    render() {
        return (
            <div className="homebenefits">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Caption title={'Engineered for Aspiring Use-Cases'} description={'Larynx.ai Technology provides Highly Available, Secure, Scalable and Flexible platform for our customers with aspiring Use-Cases.'} />
                </div>
                <div className="homebenefits_container">
                    {this.renderBenefit('Secure',require('../../images/secure.jpeg'),this.state.desc[0])}
                    {this.renderSeperator()}
                    {this.renderBenefit('Available',require('../../images/available.jpeg'),this.state.desc[1])}
                    {this.renderSeperator()}
                    {this.renderBenefit('Scalable',require('../../images/scalable.jpeg'),this.state.desc[2])}
                    {this.renderSeperator()}
                    {this.renderBenefit('Flexible Platform',require('../../images/flexible.jpeg'),this.state.desc[3])}
                </div>
            </div>
        );
    }
}

export default HomeBenefits;