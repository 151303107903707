const GETPROJECTS_START = "GETPROJECTS_START";
const GETPROJECTS_SUCC = "GETPROJECTS_SUCC";
const GETPROJECTS_FAIL = "GETPROJECTS_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GETPROJECTS_START: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case GETPROJECTS_SUCC: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case GETPROJECTS_FAIL: {
            return {
                ...state,
                data:null,
                error: action.error,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}
