import React from 'react';
import Loader from 'react-loader-spinner'
import { usePromiseTracker } from "react-promise-tracker";
import './Spinner.css'

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker();
  
    return (
      <div>
        {
          promiseInProgress && (
            <div className="spinner">
              <Loader type="Bars" color="#30217F" height={100} width={300} />
            </div>
          )
        }
    </div>
    );
  };
  

export default Spinner;