import React from 'react';
import { Link } from 'react-router-dom'

import './SideDrawer.css'

class SideDrawer extends React.Component{

    constructor(props){
        super(props);
        this.state={

        }
    }

    logOut(){
        localStorage.removeItem('user_data');
        window.location.reload();
    }

    render(){
    var props = this.props;
    var toggleLogin = (localStorage.getItem('user_data'))? true : false;
    let drawerlasses = 'side_drawer';
    if(props.show){
        drawerlasses = 'side_drawer open';
    }
    return(
        <nav className={drawerlasses}>
            <ul>
                <li onClick={props.click}><Link to="/projects">Projects</Link></li>
                <li onClick={props.click}><Link to="/editor">Editor</Link></li>
                <li onClick={props.click}><Link to="/corporatevoice">Corporate Voice</Link></li>
                {(toggleLogin)?
                                <li onClick={props.click}><Link to ="/" onClick={()=>this.logOut()}>Logout</Link></li>
                            :
                                <li onClick={props.click}><Link to="/login">Login</Link></li>
                            }
            </ul>
        </nav>
    );
    }
};

export default SideDrawer;