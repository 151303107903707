import React from 'react';
import './Project.css'
import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../../constants/constants'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

//connect to Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getBlips from '../../../data/actions/getBlips'
import updateBlips from '../../../data/actions/updateBlips'

//mapping state
function mapStateToProps(state) {
    return {
        GETBLIPS:state.getBlipsReducer
    }
}
//mapping props
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBlips,
        updateBlips
    }, dispatch);
}

class Project extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showEmpty:false,
            userdata:JSON.parse(localStorage.getItem('user_data')),
            project:this.props.location.state.project,
            blipsData:[],
            selectedAudio:null,
            playerModal:false,
            totalPageCount:[],
            pageCount:[],
            selectedPageCount:0,
            selectedDate: null,
        };
    }

    componentDidMount(){
        console.log(this.props)
        this.props.getBlips(this.state.userdata,this.state.project,this.state.selectedPageCount,"0000-00-00");
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        if (nextprops.GETBLIPS.data && nextprops.GETBLIPS.data.response && nextprops.GETBLIPS.data.response !== prevstate.blipsData) {
            var totalpages = [];
            var pages=[];
            if(nextprops.GETBLIPS.data.response.length > 0){
                for(var i = 0; i<= nextprops.GETBLIPS.data.response[0].total_page;i++){
                    totalpages.push(i);
                    if(i <10){
                        pages.push(i)
                    }
                }
            }
            return {
                showEmpty:false,
                totalPageCount:totalpages,
                pageCount:pages,
                isfetching: nextprops.GETBLIPS.isfetching,
                blipsData: nextprops.GETBLIPS.data.response
            }
        }else {
            return null;
        }
    }

    onPageLinkPress(count){
        this.props.getBlips(this.state.userdata,this.state.project,count,this.state.selectedDate)
        var selectedPageCountIndex = this.state.totalPageCount.indexOf(count);
        var totalCountIndex = this.state.totalPageCount.length - 1;
        // console.log(selectedPageCountIndex)
        // console.log(totalCountIndex)
        var tempPageCount = [];
        if(selectedPageCountIndex > 4){
            if(totalCountIndex-selectedPageCountIndex > 4){
                // console.log('here 1')
                for(var i = selectedPageCountIndex-4; i <= selectedPageCountIndex+5;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }else{
                // console.log('here 2')
                for(var i = totalCountIndex-8; i <=totalCountIndex;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }
        }else{
            if(totalCountIndex >9){
                for(var i = 0; i <10;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }else{
                for(var i = 0; i <=totalCountIndex;i++){
                    tempPageCount.push(this.state.totalPageCount[i]);
                }
            }
        }
        for (var i in tempPageCount){
            if(tempPageCount[i] == undefined){
                tempPageCount.shift();
            }
        }
        this.setState((state,props)=>{
            return{
                selectedPageCount:count,
                pageCount:tempPageCount
            }
        })
    }


    renderBottomNavigation(){
        return(
            <div className={"audit_bottomcontainer"}>
                {this.state.selectedPageCount > 0 && <div onClick={()=>this.onPageLinkPress(this.state.selectedPageCount-1)} className="audit_pagelink">{'<< Previous'}</div>}
                {this.state.pageCount.length > 0 && 
                    this.state.pageCount.map((count,index)=>{
                        return(
                            <div onClick={()=>{this.onPageLinkPress(count)}} className={(this.state.selectedPageCount == count)? "selected_audit_pagelink":"audit_pagelink"} key={'pageCount'+count}>{count+1}</div>
                        );
                    })
                }
                {this.state.totalPageCount[this.state.totalPageCount.length-1] !== this.state.selectedPageCount &&<div onClick={()=>this.onPageLinkPress(this.state.selectedPageCount+1)}  className="audit_pagelink" >{'Next >>'}</div>}
            </div>
        );
    }


    renderEmpty(){
        return(
            <div className="project_emptycontainer">
                    <h1>No Blips Found</h1>
                    <h4>You have no Blips. Create a new one and start using <h4 style={{fontWeight:'bold',marginLeft:'10px'}}>{this.state.project && this.state.project.project_name}</h4></h4>
            </div>
        );
    }

    renderPlayerModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                show={this.state.playerModal}>
                <div style={{outline:'none'}} className="voices_pie_modal">
                    <div style={{width:'100%',display:'flex',justifyContent:'flex-end',padding:'0% 0% 2% 0%'}}>
                        <div onClick={()=>this.handlePlayerModal({})} style={{alignSelf:'right',color:'blue',cursor:'pointer'}}>close</div>
                    </div>
                    <div style={{marginBottom:'3%'}} className="blip_textarea">
                        {this.state.selectedAudio && this.state.selectedAudio.text}
                    </div>
                    <AudioPlayer
                        autoPlay
                        src={(this.state.selectedAudio)? this.state.selectedAudio.audio_url+'?random = '+new Date().getTime() : ""}
                        onPlay={e => console.log("onPlay")}
                        // other props here
                    />
                </div>
            </Modal>
        );
    }

    handlePlayerModal(blip){
        var tempaudio = null;
        if(this.state.selectedAudio == null){
            console.log(blip)
            tempaudio=blip
        }
        this.setState((state,props)=>{
            return{
                playerModal:!state.playerModal,
                selectedAudio:tempaudio
            }
        })
    }

    onBlipClick(blip){
        this.props.history.push({
            pathname:'/blip',
            state: { 
                blip: blip,
                project: this.state.project
             }
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    onCalendarChange=(date)=>{
        var formattedDate = this.formatDate(date);
        this.props.getBlips(this.state.userdata,this.state.project,0,formattedDate);
        this.setState((state,props)=>{
            return{
                selectedDate:formattedDate,
                selectedPageCount:0
            }
        })
    }

    clearDateFilter(){
        this.props.getBlips(this.state.userdata,this.state.project,0,"0000-00-00");
        this.setState((state,props)=>{
            return{
                selectedDate:null,
                selectedPageCount:0
            }
        })
    }

    renderCalendar(){
        var date = (this.state.selectedDate == null)? new Date() : new Date(this.state.selectedDate);
        var mindate = new Date(new Date().setDate(new Date().getDate()-30));
        return(
            <Popover id="popover-basic">
                <Popover.Title as="h3">Select</Popover.Title>
                <Popover.Content>
                    {this.state.selectedDate != null && <div onClick={()=>this.clearDateFilter()} style={{width:'100%',textAlign:'right',paddingBottom:'1%',color:'#185DB6',cursor:'pointer'}}>clear</div>}
                    <Calendar
                        onChange={this.onCalendarChange}
                        value={date}
                        maxDate={new Date()}
                        minDate={mindate}
                    />
                </Popover.Content>
            </Popover>
        );
    }

    downloadAudio(url,name) {
        if(name.length > 8){
            name = name.substring(0,8);
        }
        var audioTemp = url;
        fetch(audioTemp+'?timestamp='+new Date().getTime(), {
            method: 'GET',
        })
            .then((response) => {
                console.log(response)
                return response.blob();
            })
            .then((blobresponse) => {
                console.log(blobresponse);
                var audio = window.URL.createObjectURL(blobresponse);
                var tempLink = document.createElement('a');
                tempLink.href = audio;
                tempLink.setAttribute('download', name +'_blip.wav');
                tempLink.click();
            })
            .catch((error) => {
                console.log(error)
        })
    }

    deleteBlip(blip_id){
        console.log('delete blip')
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'text2speech/blip/delete',
                    'method':'POST',
                    'token':this.state.userdata.token
                },
                body: JSON.stringify({
                    "blip_id":blip_id,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((jsonresponse) => {
                    console.log(jsonresponse)
                    if(jsonresponse.status){
                        this.removeBlipFromList(jsonresponse,blip_id)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        ,'modal_spinner')
    }

    removeBlipFromList(response,blip_id){
        var tempBlips = this.state.blipsData;
        var index = tempBlips.indexOf(tempBlips.find((blip)=>{return blip.blip_id == blip_id}));
        tempBlips.splice(index,1);
        console.log(tempBlips)
        var data={
            "status":response.status,
            "message":response.message,
            "data":tempBlips
        }
        console.log(data)
        this.props.updateBlips(data);
    }


    renderBlips(){
        var blipcount = this.state.blipsData.length;
        return(
            <div className="project_blipscontainer">
                <div style={{alignItems:'center'}} className="project_blipHeadRow">
                    <div style={{width:'31%',paddingLeft:'1%'}} className="headercaption">Title</div>
                    {/* <div className="headercaption">Status</div> */}
                    <div className="headercaption">voice</div>
                    <div className="headercaption headercaption_filler">sample</div>
                    <div className="headercaption headercaption_filler">sample</div>
                    <OverlayTrigger trigger="click" placement="top" overlay={this.renderCalendar()} rootClose>
                        <div className="project_calendar" style={{display:'flex',marginRight:'1%',alignItems:'center'}}>
                            <div style={{fontSize:'90%',width:'70%',color: '#185DB6'}} className="headercaption project_calendartitle">YYYY-MM-DD</div>
                            <img className="project_calendar_icon" src={require('../../../images/calendar.png')} alt={'calendar_icon'} />
                        </div>
                    </OverlayTrigger>
                </div>
                <div className="project_blipdatarow">
                    {this.state.blipsData.map((blip,index)=>{
                        return(
                            <div  key={blip.title+','+index} className={(index !== blipcount-1)? "project_blipRowcontainer_border" : "project_blipRowcontainer"}>
                                <div onClick={()=>{this.onBlipClick(blip)}} style={{width:'35%',paddingLeft:'1%',marginRight:'4%'}} className="project_bliptextdata">{blip.text}</div>
                                {/* <div className="project_blipdata">{blip.status}</div> */}
                                <div style={{fontSize:'90%'}} className="project_blipdata">{blip.model+' - '+blip.language+' - '+blip.style}</div>
                                <div style={{marginLeft:'5%'}} className="project_blipdata headercaption_filler">
                                    <div className="project_playlink">
                                        <img onClick={()=>{this.handlePlayerModal(blip)}} style={{width:'15%',cursor:'pointer'}} src={require('../../../images/play.png')} alt="play_image"/>
                                    </div>
                                </div>
                                <div className="project_blipdata headercaption_filler">
                                    <div className="project_playlink">
                                        <img style={{width:'15%',cursor:'pointer'}} onClick={()=>{this.downloadAudio(blip.audio_url,blip.text)}} src={require('../../../images/download.png')} alt="play_image"/>
                                    </div>
                                </div>
                                <div className="project_blipdata headercaption_filler">
                                    <div className="project_playlink">
                                        <img style={{width:'15%',cursor:'pointer'}} onClick={()=>{this.deleteBlip(blip.blip_id)}} src={require('../../../images/bin.png')} alt="play_image"/>
                                    </div>
                                </div>
                                <div className="project_blipdata">{blip.date}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    onCreateBlip(){
        this.props.history.push({
            pathname:'/editor',
            state: { project: this.state.project }
        });
    }

    render(){
        return(
            <div className="project_container">
                {this.renderPlayerModal()}
                <div className="project_headingdiv">
                    <Fade timeout={1000} top>
                       <div style={{display:'flex',fontWeight:'500',color:'#F3913D',fontSize:'35px'}}>{this.state.project && this.state.project.project_name}<div className="project_mainHeading"> - blips</div></div>
                        <div onClick={()=>this.onCreateBlip()} className="project_createnew">
                            <div style={{fontSize:'120%',fontWeight:'bold',marginRight:'5px'}}>+</div> ADD BLIP
                        </div>
                    </Fade>
                </div>
                <div className="project_contentdiv">
                    {this.state.showEmpty && <p className="project_blipstitle">Your Blips</p>}
                    <div className="project_blips_ListingDiv">
                        {this.state.showEmpty && this.renderEmpty()}
                        {!this.state.showEmpty && this.state.blipsData && this.renderBlips()}
                    </div>
                </div>
                {!this.state.showEmpty && this.renderBottomNavigation()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);