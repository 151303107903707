import React from 'react';
import './Create.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Fade from 'react-reveal/Fade';

//connect to Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import createNewProject from '../../../data/actions/CreateProject';
import getCreateDetails from '../../../data/actions/GetCreateDetails'

//mapping state
function mapStateToProps(state) {
    return {
        CREATEPROJECT: state.createProjectReducer,
        CREATEDETAILS: state.getCreateDetailsReducer
    }
}
//mapping props
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createNewProject,
        getCreateDetails
    }, dispatch);
}


class Create extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Projectname: '',
            Projectlanguage: '',
            profileId: 1,
            languageID: 1,
            descriptionBreif: '',
            data:[],
            languageArray:[],
            styleArray:[],
            selectedLanguage:null,
            selectedStyle:null,
            userdata:JSON.parse(localStorage.getItem('user_data'))
        }
    }

    componentDidMount() {
        console.log(this.state.userdata)
        this.props.getCreateDetails(this.state.userdata);
        // this.makeArrays(0,this.state.data,{})
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        if (nextprops.CREATEDETAILS.data && nextprops.CREATEDETAILS.data.response) {
            console.log(nextprops.CREATEDETAILS.data)
            return {
                isfetching: nextprops.CREATEDETAILS.isfetching,
                data: nextprops.CREATEDETAILS.data.response
            }
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps){
        // console.log(this.state.userdata)
        if(this.props.CREATEDETAILS !== prevProps.CREATEDETAILS && this.state.data.length>0){
            this.makeArrays(0,this.state.data,{})
        }

        if(this.props.CREATEPROJECT !== prevProps.CREATEPROJECT && this.props.CREATEPROJECT.data.length > 0){
            this.props.history.goBack();
        }

    }

    makeArrays(flag,data,criteria){

        data = data.filter(function(obj) {
            return Object.keys(criteria).every(function(c) {
              return obj[c] === criteria[c];
            });
        });

        var temp_languageArray = (flag < 1)? [] : [...this.state.languageArray];
        var temp_styleArray = [];
        for (var i in data){
            if(flag < 1){
                if(temp_languageArray.find((value)=>{return value.language === data[i].language})) {} else { temp_languageArray.push(
                    {
                        'id':data[i].language_id,
                        'language':data[i].language,
                        'dialect_id':data[i].dialect_id
                    }
                    ) }
            }
            if(temp_styleArray.find((value)=>{return value.style === data[i].style})) {} else { temp_styleArray.push(
                {
                    'id':data[i].style_id,
                    'style':data[i].style
                }
                ) }
        }

        this.setState((state,props)=>{
            return{
                languageArray:temp_languageArray,
                styleArray:temp_styleArray,
            }
        })
        // console.log(temp_languageArray)
        // console.log(temp_styleArray)
    }

    onCreate(e) {
        // console.log(this.state.userdata)
        // console.log(this.state.Projectname)
        // console.log(this.state.descriptionBreif)
        // console.log(this.state.selectedLanguage)
        // console.log(this.state.selectedStyle)
        this.props.createNewProject(
            this.state.userdata,
            this.state.Projectname,
            this.state.descriptionBreif,
            this.state.selectedLanguage,
            this.state.selectedStyle
        );
    }

    onLanguageSelect(key,event){
        this.makeArrays(1,this.state.data,
            {
                "language":this.state.languageArray[key].language,
            })
        this.setState((state,props)=>{
            return{
                selectedLanguage:state.languageArray[key],
                selectedStyle: null,
            }
        })
    }

    onStyleSelect(key,event){
        this.setState((state,props)=>{
            return{
                selectedStyle:state.styleArray[key]
            }
        })
    }

    checkStyleDisable(){
        if(this.state.selectedLanguage === null)
            return true
        else
            return false;
    }

    render() {
        var disableCreate = true;
        if(this.state.userdata.customer_category_id !== "1" && (this.state.selectedLanguage === null || this.state.selectedStyle === null )){
            disableCreate = true;
        }else if(this.state.userdata.customer_category_id == "1" && (this.state.Projectname == '' || this.state.descriptionBreif == '')){
            disableCreate = true;
        }else{
            disableCreate = false;
        }
        return (
            <div className="projectcreate">
                <div className="projectcreate_FirstRow">
                    <Fade timeout={1000} left>
                        <p className="projectcreate_createprojectStyle">Create a New Project</p>
                    </Fade>
                </div>
                <Fade timeout={1000} right>
                    <div className="projectcreate_SecoundRow">
                        <div className="projectcreate_SecoundRow_Discribtion">
                            <p className="projectcreate_boo2audio">Voice Project</p>
                            {/* <p className="projectcreate_boo2audio_Quote">Caption for creating new project</p> */}
                        </div>
                        <div className="projectcreate_FormMainStyle">
                            {this.state.userdata.customer_category_id == "1" &&
                                <div className="projectcreate_inputfeildSameline">
                                    <p className="projectcreate_ProjectName_TextStyle">Project Name</p>
                                    <p className="projectcreate_ProjectName_InputStyle">
                                        <input
                                            className="projectcreate_ProjectName_Inputid"
                                            type="text"
                                            value={this.state.Projectname}
                                            onChange={(evt) => this.setState({ Projectname: evt.target.value })}
                                        />
                                    </p>
                                </div>
                            }
                            {this.state.userdata.customer_category_id == "1" &&
                                <div className="projectcreate_inputfeildSameline">
                                    <p className="projectcreate_ProjectName_TextStyle">Description</p>
                                    <p className="projectcreate_ProjectLang_InputStyle">
                                        <input
                                            className="projectcreate_Projectlanguage_Inputid"
                                            type="text"
                                            value={this.state.descriptionBreif}
                                            onChange={(evt) => this.setState({ descriptionBreif: evt.target.value })}
                                        />
                                    </p>
                                </div>
                            }
                            {this.state.userdata.customer_category_id !== "1" &&
                                <div className="projectcreate_dropsection">
                                    <Dropdown style={{width:'100%'}} onSelect={(key,event)=>{this.onLanguageSelect(key,event)}}>
                                        <Dropdown.Toggle className="editor_section3_dropbutton" variant="Secondary" id="dropdown-basic">
                                            <div className="editor_section3_droptext">
                                                {(this.state.selectedLanguage !== null)? 
                                                    this.state.selectedLanguage.language
                                                :
                                                    'Select Language'
                                                }
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{width:'80%'}}>
                                            {this.state.languageArray.map((language,index)=>{
                                                return(
                                                    <Dropdown.Item key={'language_'+language.language} eventKey={index}>{language.language}</Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                            {this.state.userdata.customer_category_id !== "1" &&
                                <div className="projectcreate_dropsection">
                                    <Dropdown style={{width:'100%'}} onSelect={(key,event)=>{this.onStyleSelect(key,event)}}>
                                        <Dropdown.Toggle disabled={this.checkStyleDisable()} className="editor_section3_dropbutton" variant="Secondary" id="dropdown-basic">
                                            <div className="editor_section3_droptext">
                                                {(this.state.selectedStyle !== null)? 
                                                    this.state.selectedStyle.style
                                                :
                                                    'Select Style'
                                                }
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{width:'80%'}}>
                                            {this.state.styleArray.map((style,index)=>{
                                                return(
                                                    <Dropdown.Item key={'language_'+style.style} eventKey={index}>{style.style}</Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                            <div
                                style={{pointerEvents:(disableCreate)? 'none' : 'auto'}}
                                onClick={()=>this.onCreate()} 
                                className={(disableCreate)? "disabled_projectcreate_submitButtonStyle" : "projectcreate_submitButtonStyle"}>
                                    Create
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);
