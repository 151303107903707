import React from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Toolbar.css'
import logo from '../../images/Logo_09.jpg'
import Login from '../Login/Login';
import larynximage from '../../images/larynx_ai3.png';
import { Link } from 'react-router-dom'
import {Modal} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuth from '../../data/actions/setAuth'

function mapStateToProps(state) {
    return {
        SETAUTH: state.setAuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      setAuth
    }, dispatch);
}

class Toolbar extends React.Component{

    constructor(props){
        super(props);
        this.state={
            loginmodal:false,
            isLoggedIn:false,
            userData:null,
        };
    }

    componentDidMount(){
        console.log(this.props)
        if(localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')).token){
            this.props.setAuth(true,JSON.parse(localStorage.getItem('user_data')))
        }
        this.setState((state,props)=>{
            return{
                isLoggedIn:props.SETAUTH.isAuth,
                userData:props.SETAUTH.user
            }
        })
    }

    static getDerivedStateFromProps(nextprops,prevstate){
        if(nextprops.SETAUTH.isAuth){
            return{
                isLoggedIn:nextprops.SETAUTH.isAuth,
                userData:nextprops.SETAUTH.user
            }
        }
        return null;
    }

    handleLoginModal(){
        this.setState((state,props)=>{
            return {loginmodal:!state.loginmodal};
        });
    }

    renderLoginModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.loginmodal} onHide={()=>this.handleLoginModal()}>
                <Login/>
            </Modal>
    );
    }

    logOut(){
        localStorage.removeItem('user_data');
        this.props.setAuth(false)
        window.location.reload();
    }

    renderUserItems(){
        var date = (this.state.selectedDate == null)? new Date() : new Date(this.state.selectedDate);
        var mindate = new Date(new Date().setDate(new Date().getDate()-30));
        return(
            <Popover style={{width:'100%'}} id="popover-basic">
                <Popover.Title as="h3">
                    <div className="toolbar_userlist_title">
                        <img src={require('../../images/user.png')} alt={'card avatar'} className="toolbar_popover_avatar_image"/>
                        <div>{this.state.userData && this.state.userData.profile_name}</div>
                    </div>
                </Popover.Title>
                <Popover.Content>
                    <div className="toolbar_popover_listitem">
                        <img style={{width:'8%',marginRight:'5%'}} src={require('../../images/edit.png')} alt={'card avatar'} className="toolbar_popover_avatar_image"/>
                        <Link >Edit Profile</Link>
                    </div>
                    <div className="toolbar_popover_listitem_separator"></div>
                    <div className="toolbar_popover_listitem">
                        <img style={{width:'10%',marginRight:'5%'}} src={require('../../images/logout.png')} alt={'card avatar'} className="toolbar_popover_avatar_image"/>
                        <Link to ="/" onClick={()=>this.logOut()}>Logout</Link>
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    render(){
        var props = this.props;
        return(
            <header className="toolbar">
                <nav className="toolbar_navigation">
                    <div className="toolbar_toggle_button">
                        <DrawerToggleButton click={props.drawerClickHandler} />
                    </div>
                    <div className="toolbar_logo">
                        <div className=""><img alt={'logoimage'} className="toolbar_logo_image" src={logo} /></div>
                        <div className=""><a href="/"><img alt={'logo name'} className="toolbar_logo_name" src={larynximage} /></a></div>
                    </div>
                    <div className="spacer"></div>
                    <div className="toolbar_navigation_items">
                        <ul>
                            <li><Link to="/projects">Projects</Link></li>
                            {/* <li><Link to="/editor">Editor</Link></li> */}
                            <li><Link to="/corporatevoice">Corporate Voice</Link></li>
                            {/* <li><Link to={{
                                    pathname:'/market',
                                    state: { type: 'buy' }
                                }}>Market Buy</Link></li>
                                <li><Link to={{
                                    pathname:'/market',
                                    state: { type: 'sell' }
                                }}>Market Sell</Link></li> */}
                            {this.state.isLoggedIn &&
                                <OverlayTrigger trigger="click" placement="left" overlay={this.renderUserItems()} rootClose>
                                    <div>
                                        <div style={{display:'flex',alignItems:'flex-end',cursor:'pointer'}}>
                                            <img src={require('../../images/user.png')} alt={'card avatar'} className="toolbar_avatar_image"/>
                                            <div style={{fontSize:'15px',color:'orange'}}>&#x25bc;</div>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            }
                            {/* {(this.state.isLoggedIn)?
                                <li><Link to ="/" onClick={()=>this.logOut()}>Logout</Link></li>} */}
                            {!this.state.isLoggedIn && 
                                <li><Link to="/login">Login</Link></li>
                            }
                        </ul>
                    </div>
                </nav>
                {this.renderLoginModal()}
            </header>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Toolbar);