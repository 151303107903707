import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const CREATE_PROJECT_START = "CREATE_PROJECT_START";
const CREATE_PROJECT_SUCC = "CREATE_PROJECT_SUCC";
const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";

export default function createProject(user,proj_name,proj_desc,language,style) {
    if(user.customer_category_id !== "1"){
        return (dispatch) => {
            dispatch(apiCallStart())
            trackPromise(
                fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                    method: 'POST',
                    headers: {
                        'gateway':'0',
                        'path':'profile/project/create',
                        'method':'POST',
                        'token':user.token
                    },
                    body: JSON.stringify({
                        "project_name":language.language,
                        "description":style.style,
                        "language_id":language.id,
                        "style_id":style.id,
                        "dialect_id":language.dialect_id,
                        "profile_id":user.id
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                    .catch((error) => dispatch(apiCallFail(error)))
            ,'modal_spinner')
        }
    }else{
        return (dispatch) => {
            dispatch(apiCallStart())
            trackPromise(
                fetch(HOST+'/api_handler.php', {
                    method: 'POST',
                    headers: {
                        'gateway':'0',
                        'path':'profile/project/create/purchasevoice',
                        'method':'POST',
                        'token':user.token
                    },
                    body: JSON.stringify({
                        "project_name":proj_name,
                        "description":proj_desc,
                        "profile_id":user.id
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                    .catch((error) => dispatch(apiCallFail(error)))
            ,'modal_spinner')
        }
    }
}

function apiCallStart() {
    return {
        type: CREATE_PROJECT_START
    }
}

function apiCallSuccess(data) {
    return {
        type: CREATE_PROJECT_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: CREATE_PROJECT_FAIL,
        error
    }
}
