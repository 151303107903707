import React from 'react';
import './Logins.css';
import Fade from 'react-reveal/Fade';
import LoadingSpinner from '../Loading/Loadingsm';
import Loading from '../Loading/ConstanLoading';
import { Redirect } from 'react-router-dom'

//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import login from '../../data/actions/Login'
import checkEmail from '../../data/actions/CheckEmail'
import userRegistration from '../../data/actions/UserRegistration'
import setAuth from '../../data/actions/setAuth'

function mapStateToProps(state) {
    return {
        LOGIN_USER: state.loginReducer,
        CHECK_EMAIL: state.checkEmailReducer,
        USER_REG: state.userRegistrationReducer,
        SETAUTH: state.setAuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      login,
      checkEmail,
      userRegistration,
      setAuth
    }, dispatch);
}


class Login extends React.Component {


  constructor(props){
    super(props);
    this.state={
      redirectToReferrer: false,
      isOpen: true,
      signupOpen: false,
      login_selected:true,
      selected_category:null,
      username:'',
      email:'',
      emailerror:'',
      passworderror:'',
      loginpassword:'',
      registerpassword:'',
      confirmpassword:'',
      isLoggingIn:false,
      showLoading:false,
      isCheckingMail:false,
      registerMailAvailable:false,
    };
  }

  componentDidMount(){
    
  }

  static getDerivedStateFromProps(nextprops, prevstate) {
    if(prevstate.isLoggingIn && nextprops.LOGIN_USER.data && nextprops.LOGIN_USER.data.length > 0){

      localStorage.setItem('user_data',JSON.stringify(nextprops.LOGIN_USER.data[0]));
      localStorage.setItem('session_time',new Date().getTime());
      nextprops.setAuth(true,nextprops.LOGIN_USER.data[0]);
      return{
        redirectToReferrer: true,
      }
    }else if(nextprops.LOGIN_USER.data && nextprops.LOGIN_USER.data.length === 0){
      return {
        loginerror:"Incorrect Username or Password",
      }
    }

    if(!prevstate.isLoggingIn && nextprops.USER_REG.data && nextprops.USER_REG.data.length > 0){
      localStorage.setItem('user_data',JSON.stringify(nextprops.USER_REG.data[0]));
      localStorage.setItem('session_time',new Date().getTime());
      nextprops.setAuth(true,nextprops.LOGIN_USER.data[0]);
      return{
        showLoading:nextprops.USER_REG.isfetching,
        redirectToReferrer: true,
      }
    }else if(nextprops.USER_REG.data && nextprops.USER_REG.data.length === 0){
      return {
        showLoading:nextprops.USER_REG.isfetching,
        loginerror:"Incorrect Username or Password",
      }
    }

    if (prevstate.isCheckingMail && nextprops.CHECK_EMAIL.fetchCompleted && nextprops.CHECK_EMAIL.data === 1) {
      return {
        emailerror:"Email already exists",
        registerMailAvailable:false,
      }
    }else if(prevstate.isCheckingMail && nextprops.CHECK_EMAIL.fetchCompleted && nextprops.CHECK_EMAIL.data !== 1){
      return {
        registerMailAvailable:true,
      }
    }
    return null;
}

fakeAuth(){
  //logging in
  // localStorage.setItem('authToken','sample_auth_token');
  // this.setState(() => ({
  //   redirectToReferrer: true
  // }))

  // for logging out
  localStorage.removeItem('user_data');
}


  onLoginSelect(){
    this.setState((state,props)=>{
      return{
        login_selected:true
      }
    })
  }

  onRegisterSelect(){
    this.setState((state,props)=>{
      return{
        login_selected:false
      };
    })
  }

  onCategorySelect(category){
    this.setState((state,props)=>{
      return{
        selected_category:category
      };
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkEmail(){
    if(this.validateEmail(this.state.email)){
      this.props.checkEmail(this.state.email);
      this.setState((state,props)=>{
        return{
          emailerror:'',
          isCheckingMail:true,
          registerMailAvailable:false,
        }
      })
    }else{
      this.setState((state,props)=>{
        return{
          emailerror:"Invalid Email",
          isCheckingMail:false,
          registerMailAvailable:false,
        }
      })
    }
  }

  checkPasswordMatch(){
    if(this.state.registerpassword === this.state.confirmpassword){
      this.setState((state,props)=>{
        return{
          passworderror:''
        }
      })
    }else{
      this.setState((state,props)=>{
        return{
          passworderror:'Passwords not matching !!'
        }
      })
    }
  }

  onLogin(){
    this.setState((state,props)=>{
      return{
        isLoggingIn:true,
      }
    })
    this.props.login(this.state.email,this.state.loginpassword);
  }

  onSignUp(){
    console.log(this.state)
    this.setState((state,props)=>{
      return{
        showLoading:true,
        isLoggingIn:false,
      }
    })
    // console.log(this.state.username,this.state.email,this.state.selected_category,this.state.registerpassword)
    this.props.userRegistration(this.state.username,this.state.email,this.state.selected_category,this.state.registerpassword);
  }

  validateLoginButton(){
    if(this.state.loginpassword !== ''){
      return true;
    }else{
      return false;
    }
  }

  validateSignUpButton(){
    var username = this.state.username !== '' ? true : false;
    var category = this.state.selected_category !== null ? true : false;
    var email = this.state.email !== '' && this.state.emailerror === '' ? true : false;
    var password  = this.state.registerpassword !== '' && this.state.registerpassword === this.state.confirmpassword ? true : false;

    if(username && category && email && password && this.state.registerMailAvailable){
      return true; 
    }else{
      return false;
    }
  }

  renderLoginDiv(){
    console.log(this.state.emailerror)
    return(
      <div className="logincontainer">
        <Fade timeout={1000} left>
          <div className="login_left">
            <img id="login_left_logo" src={require("../../images/Logo_09.png")} alt="logo"/>
            <h4>Get Started</h4>
            <div className="provider_button">Sign in with Google</div>
            <div className="provider_button">Sign in with Facebook</div>
          </div>
        </Fade>
        <Fade timeout={1000} right>
        <div className="login_right">
          <div style={{height:'20%',width:'85%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
            <div onClick={()=>this.onLoginSelect()} className={(this.state.login_selected)? "selected_button" : "unselected_button"}>Log In</div>
            <div onClick={()=>this.onRegisterSelect()} className={(this.state.login_selected)? "unselected_button" : "selected_button"}>Register</div>
          </div>
          {this.state.login_selected ?
              <div style={{display:'flex',flexDirection:'column',width:'60%',}}>
                <h4>Log In</h4>
                <div style={{marginBottom:'10%'}}/>
                <label>Email</label>
                <input onChange={(evt)=>this.setState({email:evt.target.value})} value={this.state.email} type="text" placeholder="Enter your email here..."/>
                <div style={{marginBottom:'5%'}}/>
                <label>Password</label>
                <input onChange={(evt)=>this.setState({loginpassword:evt.target.value})} value={this.state.loginpassword} type="password" placeholder="Enter your password here..."/>
                <div style={{marginBottom:'10%'}}/>
                <div 
                  style={{pointerEvents:(this.validateLoginButton())? 'auto' : 'none'}} 
                  onClick={()=>this.onLogin()} 
                  className={(this.validateLoginButton())? "login_right_button" : "login_right_button_disabled"}>
                    Sign In
                </div>
              </div>
          :
              <div style={{display:'flex',flexDirection:'column',width:'70%',}}>
                <h4>Register</h4>
                <label>Username</label>
                <input value={this.state.username} onChange={(evt)=>this.setState({username:evt.target.value})} type="text" placeholder="Enter your username here..."/>
                <label>Category</label>
                <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                  <div onClick={()=>this.onCategorySelect(1)} className={(this.state.selected_category === 1)? "selected_category_button" : "category_button"}>Purchase Voice</div>
                  <div onClick={()=>this.onCategorySelect(2)} className={(this.state.selected_category === 2)? "selected_category_button" : "category_button"}>Voice Artist</div>
                  <div onClick={()=>this.onCategorySelect(3)} className={(this.state.selected_category === 3)? "selected_category_button" : "category_button"}>Language Expert</div>
                </div>
                <div style={{display:'flex',alignItems:'center',}}>
                  <label>Email</label>
                  <label className="error_message">{this.state.emailerror}</label>
                </div>
                <div style={{display:'flex',width:'100%'}}>
                  <input style={{width:"100%"}} type="text" 
                    value={this.state.email}
                    onBlur={()=>this.checkEmail()}
                    onChange={(evt)=>this.setState({email:evt.target.value,isCheckingMail:false,emailerror:'',registerMailAvailable:false})} 
                    placeholder="Enter your email here..."
                  />
                  <LoadingSpinner area="check_email_area" />
                </div>
                <div className="password_container">
                  <div style={{display:'flex',flexDirection:'column',width:'45%'}}>
                    <label>Password</label>
                    <input onBlur={()=>this.checkPasswordMatch()} onChange={(evt)=>this.setState({registerpassword:evt.target.value,passworderror:''})} value={this.state.registerpassword} type="password" placeholder="Your password here..."/>
                  </div>
                  <div style={{display:'flex',flexDirection:'column',width:'45%'}}>
                    <label>Confirm Password</label>
                    <input onBlur={()=>this.checkPasswordMatch()} onChange={(evt)=>this.setState({confirmpassword:evt.target.value,passworderror:''})} value={this.state.confirmpassword} type="password" placeholder="Confirm your password"/>
                    <label className="error_message">{this.state.passworderror}</label>
                  </div>
                </div>
                <div
                  style={{pointerEvents:(this.validateSignUpButton())? 'auto' : 'none'}}
                  onClick={()=>this.onSignUp()} 
                  className={(this.validateSignUpButton())? "login_right_button" : "login_right_button_disabled"}>
                    Sign Up
                  </div>
              </div>
          }
          <div style={{display:'flex',width:'60%',justifyContent:'space-between',marginTop:'2%'}}>
            {this.state.login_selected && <p className="forgotpassword">Forgot Password?</p>}
            {this.state.login_selected ? 
              <p onClick={()=>this.onRegisterSelect()} className="forgotpassword">Not a Member yet?</p>
            :
              <p onClick={()=>this.onLoginSelect()} className="forgotpassword">Go to Sign In</p>
            }
          </div>
        </div>
        </Fade>
      </div>
    );
  }


  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }
    return (
      (this.state.showLoading)?
        <Loading/>
      :
        <div className="MainDiv">
          {
            this.renderLoginDiv()
          }
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
