import React from 'react';
import Caption from '../Caption/Caption';
import './FeelTheVoice.css';

class FeelTheVoice extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    renderCards(imageurl,namelanguage){
        return(
            <div className="card_outline">
                <div className="card_image_container">
                    <img src={imageurl} alt={'card avatar'} className="card_avatar_image"/>
                    <div>
                        <p id="card_name">{namelanguage}</p>
                    </div>
                </div>
                <div className="card_text_div">
                    <p>Human Voice</p>
                    <p>Neural voice</p>
                </div>
                <div className="card_button_div">
                    <button className="tryout_button theme_color">Try Out</button>
                </div>
            </div>
        );
    };

    render(){
        return(
            <div className="feelthevoice">
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption title={'Feel The Voice'} description={'Feel the Voice and see how close it is to the voice of your choice. Our Neural voice can mimic the human voice.'}/>
                    
                </div>
                <div className="cards_container">
                    {this.renderCards(require('../../images/avatar1.jpeg'),'Matthew - Spanish')}
                    {this.renderCards(require('../../images/avatar2.jpeg'),'Julie - French')}
                    {this.renderCards(require('../../images/avatar3.jpeg'),'Amy - German')}
                    {this.renderCards(require('../../images/avatar4.jpeg'),'Kurt - English')}
                </div>
            </div>
        );
    }
}

export default FeelTheVoice;