import React from 'react';
import {HOST} from '../../constants/constants'
import './Voices.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import S3 from 'aws-s3';
import ReactPlayer from 'react-player'
import { trackPromise} from 'react-promise-tracker';
import {Modal} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Piechart from '../Piechart/Piechart';
import Loader from 'react-loader-spinner'
import Info from './information.js';


//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getTrainData from '../../data/actions/GetTrainData'
import getPrevTrainData from '../../data/actions/getPreviousTrainData'

const MicRecorder = require('mic-recorder-to-mp3');
var player;

const config = {
    bucketName: 'larynx-webdata-v2',
    dirName: 'uploads', /* optional */
    region: 'us-east-2',
    accessKeyId: 'AKIAYLJ64NZTK2HFDOVY',
    secretAccessKey: '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',
}

const ReactS3Client = new S3(config);

var recorder;


function mapStateToProps(state) {
    return {
        GET_CONTENT: state.getTrainDataReducer,
        GET_PREVCONTENT:state.getPrevTrainDataReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      getTrainData,
      getPrevTrainData
    }, dispatch);
}


class Voices extends React.Component{
    

    constructor(props){
        super(props);
        this.stopPlaying = this.stopPlaying.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.state={
            isRecording:false,
            isPlaying:false,
            content:null,
            nextContent:null,
            tempContent:null,
            contentArray:[],
            prevContentArr:[],
            newPrevContentArr:[],
            enablePlay:false,
            skiptext:true,
            piechart:false,
            showAlert:false,
            infoModal:false,
            nextContentModal:false,
            audioduration:0,
            userdata:JSON.parse(localStorage.getItem('user_data')),
            project:this.props.location.state.project,
            apiContentArray:[],
            intialUpdate:true,
            uploadingStack:[],
            selectedTagColor:true,
            selectedTag:'',
            showTranscript:false,
            showTranscriptButton:false
        }
    }

    componentDidMount(){
        console.log(this.state.project)
        this.setState((state,props)=>{
            return{
                content:null,
            }
        })
        this.props.getTrainData(this.state.project,true);
        this.props.getPrevTrainData(this.state.project);
        document.addEventListener("keydown",this.handleKeyPress,false);
    }

    handleKeyPress=(event)=>{
        // console.log(event.keyCode)

        if(!this.state.infoModal){
            switch(event.keyCode){
                case 32:{
                    if(this.state.isRecording){
                        this.stopRecording();
                    }else if(!this.state.isRecording){
                        this.startRecording();
                    }
                    break;
                }
                case 39:{
                    if(this.state.skiptext && !this.state.isRecording && !this.state.isPlaying){
                        this.skipContent();
                    }else if(!this.state.skiptext && !this.state.isRecording && !this.state.isPlaying){
                        this.nextContent();
                    }
                    break;
                }
                case 40:{
                    if(this.state.enablePlay){
                        this.onPlayPause();
                    }
                    break;
                }
                default:console.log('Key not mapped for shortcut');
            }
        }
    }

    componentWillUnmount(){
        document.removeEventListener("keydown",this.handleKeyPress,false)
    }

    static getDerivedStateFromProps(nextprops, prevstate) {
        // console.log(nextprops)
        // console.log(prevstate.apiContentArray)
        // console.log(prevstate.content)
        if(nextprops.GET_CONTENT.data && nextprops.GET_CONTENT.data !== prevstate.apiContentArray ){
            if(prevstate.content == null){
                return{
                    content:nextprops.GET_CONTENT.data[0],
                    nextContent:(nextprops.GET_CONTENT.data.length>1)? nextprops.GET_CONTENT.data[1] : nextprops.GET_CONTENT.data[0],
                    contentArray:nextprops.GET_CONTENT.data,
                    apiContentArray:nextprops.GET_CONTENT.data,
                    selectedTagColor:(prevstate.selectedTag !== nextprops.GET_CONTENT.data[0].tag)? !prevstate.selectedTagColor : prevstate.selectedTagColor,
                    selectedTag:nextprops.GET_CONTENT.data[0].tag,
                }
            }else{
                var temp = [];
                for(var i in nextprops.GET_CONTENT.data){
                    temp.push(nextprops.GET_CONTENT.data[i])
                }
                console.log(temp)
                return{
                    contentArray:temp,
                    apiContentArray:nextprops.GET_CONTENT.data,
                }
            }
        }

        if(nextprops.GET_PREVCONTENT.data && nextprops.GET_PREVCONTENT.data !== prevstate.prevContentArr && prevstate.intialUpdate){
            return{
                prevContentArr:nextprops.GET_PREVCONTENT.data,
                intialUpdate:false
            }
        }
    
        return null;
    }


    handlePieModal(){
        if(this.state.piechart){
            this.setState((state,props)=>{
                return {piechart:false};
            });
        }else{
            this.getCompletionData();
        }
    }

    calculatePercentage(value,totalvalue){
        return (value/totalvalue)*100;
    }

    getCompletionData() {
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'traincontent/completionstatus/profileid/'+this.state.project.project_id+'/languageid/'+this.state.project.language_id,
                    'method':'GET'
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((myJson) => {
                    // console.log(myJson)
                    this.setState((state,props)=>{
                        return{
                            // dataPoints: [
                            //     { y: this.calculatePercentage(myJson[0].completeCount,myJson[0].totalCount), label: "Completed" },
                            //     { y: this.calculatePercentage(myJson[0].rejectedCount,myJson[0].totalCount), label: "Rejected" },
                            //     { y: this.calculatePercentage(myJson[0].verifiedCount,myJson[0].totalCount), label: "Verified" },
                            // ],
                            dataPoints: [
                                { y: myJson[0].completeCount, label: "Completed" },
                                { y: myJson[0].rejectedCount, label: "Rejected" },
                                { y: myJson[0].verifiedCount, label: "Verified" },
                            ],
                            piechart:true
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
            ,'modal_spinner');
    }

    handleInfoModal(){
        this.setState((state,props)=>{
            return {infoModal:!state.infoModal};
        });
    }

    renderInfoModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.infoModal} onHide={()=>this.handleInfoModal()}>
                <div className="voices_pie_modal">
                    <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}>
                        <img onClick={()=>{this.handleInfoModal()}} style={{width:'3%',cursor:'pointer'}} src={require('../../images/close.png')} alt={'pie chart'}/>
                    </div>
                    <div className="voices_infomodal_header">Guidelines</div>
                    <div style={{width:'100%',display:'flex',justifyContent:'center',outline:'none',margin:'2% 0%'}}>
                        <ReactPlayer url={Info.videoUrl} playing controls/>
                    </div>
                    <div className="voice_infomodal_guidescontainer">
                        {Info.guidelines.map((guide,index)=>{
                            return(
                                <div style={{margin:'2% 0%'}} key={'guideline'+index}>{guide}</div>
                            );
                        })}
                        <div style={{marginTop:'4%'}}>
                            <div style={{fontWeight:'bold',fontSize:'95%'}}>Shortcuts</div>
                                <div style={{display:'flex',}}>
                                    {Info.shortcuts.map((key,index)=>{
                                        return(
                                            <div style={{margin:'0% 2%',display:'flex',flexDirection:'column',alignItems:'center'}} key={'key'+index}>
                                                <img style={{width:'50%'}} src={key.key} alt={'shortcut key'}/>
                                                <div style={{fontSize:'75%'}}>{key.desc}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                    </div>
                </div>
            </Modal>
        );
    }

    handleNextContentModal(){
        this.setState((state,props)=>{
            return {nextContentModal:!state.nextContentModal};
        });
    }

    renderNextContentModal(){
        var currentIndex = this.state.contentArray.indexOf(this.state.contentArray.find((content)=> content.orderId == this.state.content.orderId));
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.nextContentModal} onHide={()=>this.handleNextContentModal()}>
                <div className="voices_pie_modal">
                    <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}>
                        <img onClick={()=>{this.handleNextContentModal()}} style={{width:'3%',cursor:'pointer'}} src={require('../../images/close.png')} alt={'pie chart'}/>
                    </div>
                    <div className="voices_infomodal_header">Upcoming Content</div>
                    <div className="voice_infomodal_guidescontainer">
                        {this.state.contentArray.map((content,index)=>{
                            if(index >currentIndex){
                                return(
                                    <div style={{margin:'2% 0%'}} key={'guideline'+index}>{content.text}</div>
                                );
                            }
                        })}
                    </div>
                </div>
            </Modal>
        );
    }


    renderPieModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.piechart} onHide={()=>this.handlePieModal()}>
                <div className="voices_pie_modal">
                    <Piechart data={this.state.dataPoints}/>
                </div>
            </Modal>
        );
    }

    handleAlertModal(){
        if(this.state.showAlert){
            this.setState((state,props)=>{
                return {showAlert:false};
            });
        }
    }

    renderAlertModal(){
        return(
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showAlert} onHide={()=>this.handleAlertModal()}>
                <div style={{fontWeight:'bold',display:'flex',flexDirection:'column',alignItems:'center',}} className="voices_pie_modal">
                    <div style={{marginBottom:'10px',fontSize:'20px'}}>The Audio seems to be too Long.... Please Re-record</div>
                    <div onClick={()=>this.handleAlertModal()} className="blip_button">OK</div>
                </div>
            </Modal>
        );
    }
    
    skipContent(){
        // trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'skiptraindata',
                    'method':'POST'
                },
                body: JSON.stringify({
                    'fk_language_id': this.state.project.language_id,
                    'fk_lj_id': this.state.content.ljId,
                    'fk_profile_id': this.state.project.project_id,
                    'fk_order_id': this.state.content.orderId,
                }),
            })
            .then((response) => {
                // if (response.headers.get('content-type').match(/application\/json/)) {
                    return response.json();
                // }
                // return response;
            })
            .then((jsonresponse) => {
                console.log('skipcontent',jsonresponse)
                // this.props.getTrainData(this.state.project);
            })
            .catch((error) => {
                console.log(error)
            })
        // ,'modal_spinner');
        this.nextContent();
    }

    nextContent(){
        if(this.state.tempContent == null){
            var currentIndex = this.state.contentArray.indexOf(this.state.contentArray.find((content)=> content.orderId == this.state.content.orderId));
            this.setState((state,props)=>{
                return{
                    enablePlay:false,
                    skiptext:true,
                    content: (currentIndex < (this.state.contentArray.length -2))? state.contentArray[currentIndex+1] : state.content,
                    nextContent:(currentIndex < (this.state.contentArray.length -2))? state.contentArray[currentIndex+2] : state.nextContent,
                    prevContentArr:(state.newPrevContentArr.length > 0)? state.newPrevContentArr : state.prevContentArr,
                    selectedTagColor:(state.selectedTag !== state.contentArray[currentIndex+1].tag)? !state.selectedTagColor : state.selectedTagColor,
                    selectedTag:state.contentArray[currentIndex+1].tag,
                }
            })
            if(currentIndex === (this.state.contentArray.length -4)){
                this.props.getTrainData(this.state.project,false)
            }
        }else{
            this.setState((state,props)=>{
                return{
                    enablePlay:false,
                    content:state.tempContent,
                    tempContent:null,
                    skiptext:true
                }
            })
        }
        //transcript code
        this.setState((state,props)=>{
            return{
                showTranscript:false,
                showTranscriptButton:false
            }
        })
    }

    startRecording(){
        recorder= new MicRecorder({
            bitRate: 320
        });
        recorder.start().then(() => {
            // something else
            this.setState((state,props)=>{
                return{
                    isRecording:true,
                    enablePlay:false,
                    audioduration:new Date().getTime(),
                }
            })
          }).catch((e) => {
            console.error(e);
          });
    }

    stopPlaying(){
        this.setState((state,props)=>{
            return{
                isPlaying:false
            }
        })
    }

    stopRecording(){
        var tempUploadingStack = this.state.uploadingStack;
        tempUploadingStack.push(this.state.content);
        this.setState((state,props)=>{
            return{
                uploadingStack:tempUploadingStack
            }
        })
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, 'me-at-thevoice.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });
                player = new Audio(URL.createObjectURL(file));
                player.onended = this.stopPlaying;
                // console.log(new Date().getTime() - this.state.audioduration)
                if(new Date().getTime() - this.state.audioduration > 30000){
                    this.setState((state,props)=>{
                        return{
                            showAlert:true
                        }
                    })
                }else{
                    this.onUpload(file)
                    this.reportUpload()
                }
                // player.play();
                this.setState((state,props)=>{
                    return{
                        isRecording:false,
                        audioduration:new Date().getTime() - state.audioduration,
                        //added after unblocked uploading
                        enablePlay:true,
                        skiptext:false,
                    }
                })
            }).catch((e) => {
                alert('We could not retrieve your message');
                console.log(e);
            });

    }

    async onUpload(file) {
        
        var name = this.state.project.project_id+'/'+this.state.content.ljId;
        console.log(file,name)
        // trackPromise(
            ReactS3Client.uploadFile(file, name)
                .then(data => {
                    console.log('successful')
                })
                .catch(err => console.error('S3 upload error',err))
        // ,'modal_spinner')
    }
    
    reportUpload(){
        // trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'1',
                    'path':'traincontent/upload',
                    'method':'POST'
                },
                body: JSON.stringify({
                    'fk_language_id': this.state.project.language_id,
                    'fk_lj_id': this.state.content.ljId,
                    'fk_profile_id': this.state.project.project_id,
                    'fk_lj_order_id': this.state.content.orderId,
                    's3_url': 'https://larynx-webdata-v2.s3-us-east-2.amazonaws.com/uploads/'+this.state.project.project_id+'/'+this.state.content.ljId+".mp3",
                    'is_varified': 0,

                }),
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => {
                    console.log(jsonresponse)
                    console.log('reported upload .. ')
                    this.removeFomUploadStack(this.state.content);
                    this.setState((state,props)=>{
                        return{
                            // uploadingStack:tempUploadingStack,
                            enablePlay:true,
                            skiptext:false,
                            newPrevContentArr:jsonresponse.response
                        }
                    })
                    // this.nextContent();
                })
                .catch((error) => {
                    console.log(error)
                })
        // ,'modal_spinner');
    }

    removeFomUploadStack(upload){
        var tempUploadingStack = this.state.uploadingStack;
        var index = tempUploadingStack.indexOf(tempUploadingStack.find((item)=>item.orderId == upload.orderId));
        tempUploadingStack.splice(index,1);
        this.setState((state,props)=>{
            return{
                uploadingStack:tempUploadingStack
            }
        })
    }

    onPlayPause(){
        if(this.state.isPlaying){
            player.pause();
            this.setState((state,props)=>{
                return{
                    isPlaying:false
                }
            })
        }else{
            player.play();
            this.setState((state,props)=>{
                return{
                    isPlaying:true
                }
            })
        }
    }

    onAudit(){
        this.props.history.push({
            pathname:'/audit',
            state: { project: this.state.project }
        });
    }

    onPreviousContentPress(prev_content){
        // console.log(prev_content,this.state.content)
        player = new Audio(prev_content.s3_url);
        player.onended = this.stopPlaying;
        if(this.state.content.is_recorded){
            this.setState((state,props)=>{
                return{
                    content:prev_content,
                    skiptext:false,
                    enablePlay:true,
                }
            })
        }else{
            this.setState((state,props)=>{
                return{
                    tempContent:state.content,
                    content:prev_content,
                    skiptext:false,
                    enablePlay:true,
                }
            })
        }
        //transcript code
        if(prev_content.is_verified != 0){
            this.setState((state,props)=>{
                return{
                    showTranscript:true,
                    showTranscriptButton:true
                }
            })
        }else{
            this.setState((state,props)=>{
                return{
                    showTranscript:false,
                    showTranscriptButton:false
                }
            })
        }
    }

    onPdfDownload(fileURL, fileName){
        // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
	       if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href; 
// window event not working here
			}else{
		        var evt = new MouseEvent('click', {
		            'view': window,
		            'bubbles': true,
		            'cancelable': false
		        });
		        save.dispatchEvent(evt);
		        (window.URL || window.webkitURL).revokeObjectURL(save.href);
			}	
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
    }

    handleTranscript(){
        this.setState((state,props)=>{
            return{
                showTranscript:!state.showTranscript
            }
        })
    }

    render(){
        return(
            <div className="voices">
                {this.state.dataPoints && this.renderPieModal()}
                {this.renderAlertModal()}
                {this.renderInfoModal()}
                {this.state.nextContent && this.state.contentArray && this.state.contentArray.length>0 && this.state.content && this.state.content.orderId && this.renderNextContentModal()}
                <div className="voices_section1">
                    <div style={{display:'flex',alignSelf:'flex-end',width:'25%',justifyContent:'center',}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip id="overlay-example" {...this.props}>Download PDF</Tooltip>}>
                            <img onClick={()=>{this.onPdfDownload(this.state.project.pdf_url,"file.pdf")}} style={{padding:'2%'}} className={"voices_pie_chart"} src={require('../../images/pdf.png')} alt={'pie chart'}/>
                        </OverlayTrigger>
                    </div>
                    <div className="voices_section1_info">
                        <div className="voices_section1_infolinecontainer">
                            <div style={{height:'40%',display:'flex'}}>{this.state.content && this.state.content.language}</div>
                            {/* <div style={{height:'40%',display:'flex'}}>
                                <p>Click  </p>
                                <img style={{height:'70%'}} src={require('../../images/microphone_branch.png')} alt="mike"/>
                                <p>  then read the sentence aloud</p>
                            </div> */}
                            <div style={{height:'40%',display:'flex'}}>{this.state.content && this.state.content.style}</div>
                            <div style={{height:'40%',display:'flex',color:(this.state.selectedTagColor)? '#F3913D' : '#2F2180'}}>{(this.state.content && this.state.content.tag !== null)? this.state.content.tag : ''}</div>
                        </div>
                    </div>
                    <div style={{display:'flex',alignSelf:'flex-end',width:'25%',justifyContent:'center',}}>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip id="overlay-example" {...this.props}>Audit</Tooltip>}>
                            <img onClick={()=>{this.onAudit()}} className={"voices_pie_chart"} src={require('../../images/audit.png')} alt={'pie chart'}/>
                        </OverlayTrigger>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip id="overlay-example" {...this.props}>Completion status</Tooltip>}>
                            <img onClick={()=>{this.handlePieModal()}} className={"voices_pie_chart"} src={require('../../images/pie-chart.png')} alt={'pie chart'}/>
                        </OverlayTrigger>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip id="overlay-example" {...this.props}>Information</Tooltip>}>
                            <img onClick={()=>{this.handleInfoModal()}} className={"voices_pie_chart"} src={require('../../images/info.png')} alt={'pie chart'}/>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="voices_section2">
                    <div className="voices_section2_prevcontent_container">
                        {this.state.uploadingStack.length>0 && <div style={{fontSize:'80%',fontWeight:'bold'}}>uploading....</div>}
                        <div onClick={()=>{this.handleNextContentModal()}} className="voices_nextContent">
                            {this.state.nextContent && this.state.nextContent.text}
                        </div>
                        {this.state.uploadingStack.length>0 && false &&
                            this.state.uploadingStack.slice(0).reverse().map((uploading,index)=>{
                                return(
                                    <div key={uploading.orderId+'index'} className="voices_prevcontent_uploadingoutline">
                                        <div className="voices_prevcontenttext_uploading">{uploading.text}</div>
                                        {/* <Loader type="Bars" color="#30217F" width={30} height={30}/> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="voices_textbox">
                        {this.state.content && <p style={{fontSize:'200%',textAlign:'center'}}>{this.state.content.text}</p>}
                    </div>
                    <div className="voices_section2_prevcontent_container">
                        {this.state.prevContentArr.length > 0 &&
                            this.state.prevContentArr.map((content,index)=>{
                                return(
                                    <div 
                                    style={{background:(content.is_verified == 0)? '#ffffff': (content.is_verified == 2)?'#fcf0f0' : '#d8ffd8'}} 
                                    onClick={()=>{this.onPreviousContentPress(content)}} key={content.ljId+','} 
                                    className="voices_prevcontent_outline">
                                        <div className="voices_prevcontenttext">{content.text}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div style={{marginTop:'-2.4%',marginBottom:'0%',display:'flex',justifyContent:'center',}}>
                    <div onClick={()=>{this.onPlayPause()}} className={(this.state.enablePlay)? "voices_button_enabled" : "voices_button"}>{(this.state.isPlaying)?'Pause' : 'Play'}</div>
                    <div style={{width:'40%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.state.showTranscriptButton && <div onClick={()=>{this.handleTranscript()}} className={"voices_showtranscript"}>{(this.state.showTranscript)?'Back to Record' : 'Show Transcript'}</div>}
                    </div>
                    <div onClick={()=>(this.state.skiptext)? this.skipContent() : this.nextContent()} className={(this.state.isRecording || this.state.isPlaying)? "voices_button" : "voices_button_enabled"}>{(this.state.skiptext)? 'Skip' : 'Next'}</div>
                </div>
                {/* <div className="voices_section3">
                    {(this.state.isRecording)&&
                        <img className="voices_waveform" src={require('../../images/waveform.gif')} alt={'waveform'}/>
                    }
                    {(this.state.isRecording)?
                        <div style={{marginLeft:'-35%'}} onClick={()=>{this.stopRecording()}} className="voices_section3_button">
                            <img style={{height:'35%',}} src={require('../../images/stop_orange.png')} alt="mike"/>
                        </div>
                    :
                        <div onClick={()=>this.startRecording()} className="voices_section3_button">
                            <img style={{height:'35%'}} src={require('../../images/microphone_branch.png')} alt="mike"/>
                        </div>
                    }
                </div> */}
                {this.state.isRecording && !this.state.showTranscript &&
                    <div className="voices_section3">
                        <img className="voices_waveform" src={require('../../images/waveform.gif')} alt={'waveform'}/>
                        <div style={{marginLeft:'-35%'}} onClick={()=>{this.stopRecording()}} className="voices_section3_button">
                            <img style={{height:'35%',}} src={require('../../images/stop_orange.png')} alt="mike"/>
                        </div>
                    </div>
                }
                {!this.state.isRecording && !this.state.showTranscript &&
                    <div className="voices_section3">
                        <div onClick={()=>this.startRecording()} className="voices_section3_button">
                            <img style={{height:'35%'}} src={require('../../images/microphone_branch.png')} alt="mike"/>
                        </div>
                    </div>
                }
                {this.state.showTranscript && this.state.content && this.state.content.text_accuracy && 
                    <div className="voices_section3">
                        <div className="transcriptdiv_container">
                            <div className="transcriptdiv_caption">Transcribed Text</div>
                            <div className="voices_section3_transcriptdiv">
                                <div className="transcribed_text">{this.state.content.speechtotext}</div>
                                <div className="transcribed_accuracycaption">{'Accuracy'}
                                    <div className="transcribed_accuracy" 
                                    style={{color:(Number(this.state.content.text_accuracy)>=95)? 'green' : (Number(this.state.content.text_accuracy)>50)? 'red' : 'red'}}>
                                        {Math.floor(this.state.content.text_accuracy)+'%'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Voices);