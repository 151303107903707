import React from 'react';
import Fade from 'react-reveal/Fade';
import ProgressBar from 'react-bootstrap/ProgressBar'

var player;

class Buy extends React.Component{

    constructor(props){
        super(props);
        this.stopPlaying = this.stopPlaying.bind(this);
        this.updatePlayerTime = this.updatePlayerTime.bind(this);
        this.state={
            searchtext:"",
            email:"",
            screenHeight:0,
            screenWidth:0,
            playingId:null,
            isPlaying:false,
            popularPlayerPerc:0,
        }
        window.addEventListener("resize",this.update)
    }

    componentDidMount(){
        this.update();
    }


    update=()=>{
        this.setState((state,props)=>{
            return{
                screenHeight:window.innerHeight,
                screenWidth:window.innerWidth
            }
        })
    }

    componentWillUnmount(){
        window.removeEventListener("resize",this.update)
    }

    playAudio(audioId,url){
        player = new Audio(url);
        player.play();
        player.ontimeupdate = this.updatePlayerTime;
        player.onended = this.stopPlaying;
        this.setState((state,props)=>{
            return{
                isPlaying:true,
                playingId:audioId
            }
        })
    }

    updatePlayerTime(event){
        // console.log(Math.floor(event.path[0].currentTime))
        var perc = (event.path[0].currentTime/event.path[0].duration)*100;
        // console.log(perc)
        this.setState((state,props)=>{
            return{
                popularPlayerPerc:perc
            }
        })
    }

    stopPlaying(){
        this.setState((state,props)=>{
            return{
                playingId:null,
                isPlaying:false,
                popularPlayerPerc:0,
            }
        })
    }

    pauseAudio(audioId){
        player.pause();
        this.setState((state,props)=>{
            return{
                isPlaying:false,
                popularPlayerPerc:0,
                playingId:null
            }
        })
    }


    renderCards(imageurl,namelanguage,audioUrl){
        var audioId = 'buy_'+imageurl+'_audio_tag';
        return(
            <div className="buy_card_outline">
                <div className="buy_popularcard_image_container">
                    <img src={imageurl} alt={'card avatar'} className="card_avatar_image"/>
                    <div style={{paddingTop:'5%'}}>
                        <div id="buy_card_name">{namelanguage}</div>
                    </div>
                </div>
                <div style={{padding:'1%'}} className="buy_card_text_div">
                    {this.state.playingId == audioId && this.state.isPlaying ?
                        <input type="image" src={require('../../images/pause.png')} alt="pausebuttton" onClick={()=>this.pauseAudio(audioId,audioUrl)} id="buy_popular_play"/>
                    :
                        <input type="image" src={require('../../images/play.png')} alt="playbuttton" onClick={()=>this.playAudio(audioId,audioUrl)} id="buy_popular_play"/>
                    }
                    <div style={{width:'80%',margin:'2% 2% 0% 2%'}}>
                        <ProgressBar style={{height:'50%'}} animated now={(this.state.playingId == audioId)? this.state.popularPlayerPerc : 0} />
                    </div>
                </div>
                <div className="buy_popularcard_button_div">
                    <button className="buy_populartryout_button">Buy</button>
                </div>
            </div>
        );
    };

    onMarketPress(type){
        this.props.history.push({
            pathname:'/market',
            state: { type: type }
        });
    };
    
    renderBanner(){
        return(
            <div className="swiper_slide">
                <div className="swiper_slide_frame">
                    <div className="swiper_slide_headings_black">
                        <h1 style={{fontWeight:'bold',}}>Monetize  your voice.</h1>
                        {this.state.screenWidth <= 700 &&
                                <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                        }
                        <h4 style={{color:'grey'}}>Want to monetize your voice? Yes, you should. It’s very profitable, and very simple at Larynx.ai.</h4>
                    </div>
                    <div className="swiper_slide_buttons">
                        <button className="swiper_mainimage_button_selected swiper_button">Buy</button>
                        <button onClick={()=>{this.onMarketPress('sell')}} className="swiper_mainimage_button swiper_button">Sell</button>
                    </div>
                </div>
                {this.state.screenWidth >700 &&
                    <div className="swiper_slide_frame2">
                        <img className="swiper_slide_image" src={require('../../images/marketplace.png')} alt="marketplace" />
                    </div>
                }
            </div>
        );
    }

    renderMostPopular(){
        return(
            <div style={{paddingTop:'0'}} className="buy_section_container">
                <div className="buy_sectioncaption">MOST POPULAR</div>
                <div className="buy_horizontal_divider"></div>
                <div style={{marginTop:'-1%',marginBottom:'2%'}}>Most popular / mesmeric voices according to the Larynx.ai surveys.</div>
                <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                    {this.renderCards(require('../../images/avatar1.jpeg'),'Matthew - Spanish','https://www.kozco.com/tech/piano2.wav')}
                    {this.renderCards(require('../../images/avatar2.jpeg'),'Julie - French','https://www.kozco.com/tech/organfinale.wav')}
                    {this.renderCards(require('../../images/avatar3.jpeg'),'Amy - German','https://www.kozco.com/tech/piano2.wav')}
                    {this.renderCards(require('../../images/avatar4.jpeg'),'Kurt - English','https://www.kozco.com/tech/organfinale.wav')}
                </div>
            </div>
        );
    }

    onSearchChange(text){
        this.setState((state,props)=>{
            return{
                searchtext:text
            }
        })
    }

    renderSearchPopular(imageurl,namelanguage){
        return(
            <div className="buy_section_container">
                <div className="buy_searchheader">
                    <div className="buy_sectioncaption buy_searchheader_text">Category</div>
                    <input 
                        placeholder={'Find your voice, Search by name, language, context, etc..'} 
                        className="buy_search_input" value={this.state.searchtext} 
                        onChange={(e)=>this.onSearchChange(e.target.value)}/>
                    <div className="buy_searchheader_button">Search</div>
                </div>
                <div className="buy_horizontal_divider"></div>
                <div style={{marginTop:'-1%',marginBottom:'2%'}}>Search the voice of your choice in Larynx.ai. Categorized by language, context, gender, etc,.</div>
                <div className="buy_searchbody">
                    <div className="buy_searchbody_leftsection">
                        <div>
                            <div className="buy_searchbody_leftcaption">Language</div>
                            <div className="buy_searchbody_leftoption">English</div>
                            <div className="buy_searchbody_leftoption">Malayalam</div>
                            <div className="buy_searchbody_leftoption">Spanish</div>
                        </div>
                        <div style={{marginTop:'10%',marginBottom:'10%'}} className="buy_horizontal_divider"></div>
                        <div>
                            <div className="buy_searchbody_leftcaption">Gender</div>
                            <div className="buy_searchbody_leftoption">Male</div>
                            <div className="buy_searchbody_leftoption">Female</div>
                        </div>
                    </div>
                    <div className="buy_searchbody_Rightsection">
                        <div className="buy_searchbody_context buy_searchbody_context_1">News Reading</div>
                        <div className="buy_searchbody_context buy_searchbody_context_2">Book Reading</div>
                        <div className="buy_searchbody_context buy_searchbody_context_3">Celebrity</div>
                        <div className="buy_searchbody_context buy_searchbody_context_4">Documentary</div>
                        <div className="buy_searchbody_context buy_searchbody_context_5">Conversational</div>
                        <div className="buy_searchbody_context buy_searchbody_context_6">...</div>
                    </div>
                </div>
            </div>
        );
    };

    renderSellSection(){
        return(
            <div style={{background:'#2E207C',}} className="buy_section_container">
                <div className="buy_sell_caption">Sell your voice - New, Better way to monetize your voice</div>
                <div className="buy_horizontal_divider"></div>
                <div style={{width:'100%',justifyContent:'space-between'}}>
                    {/* <div className="buy_sell_option">* According to market research about 91% of the companies are buying Voice Technology.</div>
                    <div className="buy_sell_option">* Larynx.ai, the neural voice technology will help Voice Artist with new, better, profitable and simple way to monetize their voice.</div>
                    <div className="buy_sell_option">* You can build and publish your neural voice. We will provide you many channels and avenues to market and sell your Voice.</div>
                    <div className="buy_sell_option">* Take the first mover advantage by becoming part of the voice user interface “In-demand” Technology.</div>
                    <div className="buy_sell_option">* Join us now to generate revenue and become the “Voice in demand”.</div> */}
                    <div style={{padding:'0% 2%'}} className="buy_sell_option">According to market research about 91% of the companies are buying Voice Technology.
                     Larynx.ai, the neural voice technology will help Voice Artist with new, better, profitable and simple way to monetize 
                     their voice. You can build and publish your neural voice. We will provide you many channels and avenues to market and sell 
                     your Voice. Take the first mover advantage by becoming part of the voice user interface “In-demand” Technology. Join us now 
                     to generate revenue and become the “Voice in demand”.</div>
                </div>
                <div style={{width:'100%',display:'flex'}}>
                    <input placeholder={'Enter your email address...'} style={{width:'40%',outline:'none'}} value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}/>
                    <div className="buy_sell_button">Get Started</div>
                </div>
        </div>
        );
    }

    render(){
        return(
            <div className="buy">
                {this.renderBanner()}
                <Fade timeout={1000} bottom>
                    <div>
                    {this.renderMostPopular()}
                    {this.renderSearchPopular()}
                    </div>
                </Fade>
                
                {this.renderSellSection()}
            </div>
        );
    }
}

export default Buy;