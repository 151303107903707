import { combineReducers } from 'redux';
import userRegistrationReducer from './modules/UserRegistration'
import loginReducer from './modules/Login'
import checkEmailReducer from './modules/CheckEmail'
import skipTrainDateReducer from './modules/SkipTrainData'
import getTrainDataReducer from './modules/GetTrainData'
import createProjectReducer from './modules/CreateProject'
import getCreateDetailsReducer from './modules/GetCreateDetails'
import listProjectsReducer from './modules/ListProjects'
import getAuditDataReducer from './modules/getAuditData'
import setAuthReducer from './modules/setAuth'
import getPrevTrainDataReducer from './modules/getPreviousTrainData'
import getModalDetailsReducer from './modules/getModalDetails'
import getBlipsReducer from './modules/getBlips'

const rootReducer = combineReducers({
    userRegistrationReducer,
    loginReducer,
    checkEmailReducer,
    skipTrainDateReducer,
    getTrainDataReducer,
    createProjectReducer,
    getCreateDetailsReducer,
    listProjectsReducer,
    getAuditDataReducer,
    setAuthReducer,
    getPrevTrainDataReducer,
    getModalDetailsReducer,
    getBlipsReducer
    
});

export default rootReducer;