import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const CHECK_EMAIL_START = "CHECK_EMAIL_START";
const CHECK_EMAIL_SUCC = "CHECK_EMAIL_SUCC";
const CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

export default function checkEmail(mail) {
    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'verification',
                    'method':'POST'
                },
                body: JSON.stringify({
                    "email":mail,
                }),
            })
                .then((response) => {
                    if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    }
                    return response;
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        ,'check_email_area')
    }
}

function apiCallStart() {
    return {
        type: CHECK_EMAIL_START
    }
}

function apiCallSuccess(data) {
    return {
        type: CHECK_EMAIL_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: CHECK_EMAIL_FAIL,
        error
    }
}
