import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap';


class Expiry extends React.Component{

    constructor(props){
        super(props);
        this.state={
        }
    }

    handleAlertModal(){
        if(this.state.showModal){
            this.setState((state,props)=>{
                return {showModal:false};
            });
        }
    }

    logOut(){
        this.setState((state,props)=>{
            return{
                showModal:false
            }
        })
        localStorage.removeItem('user_data');
        window.location.reload();
    }




    render(){
        return(
            <div>
                <Modal
                    size="lg"
                    animation={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.showModal} onHide={()=>this.handleAlertModal()}>
                    <div style={{fontWeight:'bold',display:'flex',flexDirection:'column',alignItems:'center',}} className="voices_pie_modal">
                        <div style={{marginBottom:'10px',fontSize:'20px'}}>Session Expired.. Please login</div>
                        <div onClick={()=>this.logOut()} className="blip_button">OK</div>
                    </div>
                </Modal>
            </div>
        );
    }
}


export default Expiry;