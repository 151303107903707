import React from 'react';
import './Market.css'
import Buy from './Buy';
import Sell from './Sell';

class Market extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        var location = this.props.location;
        return(
            <div className="market">
                {(location.state && location.state.type && location.state.type === 'sell')?
                    <Sell history={this.props.history}/>
                :
                    <Buy history={this.props.history}/>
                }
            </div>
        );
    }
}

export default Market;