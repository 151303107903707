import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GETPROJECTS_START = "GETPROJECTS_START";
const GETPROJECTS_SUCC = "GETPROJECTS_SUCC";
const GETPROJECTS_FAIL = "GETPROJECTS_FAIL";

export default function listProjects(userdata) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'profile/'+userdata.id+'/project/list',
                    'method':'GET',
                    'token':userdata.token
                }
            })
                .then((response) => {
                    if(response.status >= 400){
                        return response;
                    }else{
                        return response.json();
                    }
                })
                .then((jsonresponse) => {
                    if(jsonresponse.status){
                        dispatch(apiCallFail(jsonresponse))
                    }else{
                        dispatch(apiCallSuccess(jsonresponse))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(apiCallFail(error))
                })
        )
    }
}

function apiCallStart() {
    return {
        type: GETPROJECTS_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GETPROJECTS_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GETPROJECTS_FAIL,
        error
    }
}
