import { trackPromise} from 'react-promise-tracker';
import {HOST} from '../../constants/constants'

const GETMODALDETAILS_START = "GETMODALDETAILS_START";
const GETMODALDETAILS_SUCC = "GETMODALDETAILS_SUCC";
const GETMODALDETAILS_FAIL = "GETMODALDETAILS_FAIL";

export default function getModalDetails(userdata) {
    // console.log(userdata)
    return (dispatch) => {
        dispatch(apiCallStart())
        trackPromise(
            fetch(HOST+'/api_handler.php?timestamp='+new Date().getTime(), {
                method: 'POST',
                headers: {
                    'gateway':'0',
                    'path':'text2speech/models/active/view',
                    'method':'GET',
                    'token':userdata.token
                }
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => dispatch(apiCallSuccess(jsonresponse)))
                .catch((error) => dispatch(apiCallFail(error)))
        )
    }
}

function apiCallStart() {
    return {
        type: GETMODALDETAILS_START
    }
}

function apiCallSuccess(data) {
    return {
        type: GETMODALDETAILS_SUCC,
        data,
    }
}

function apiCallFail(error) {
    return {
        type: GETMODALDETAILS_FAIL,
        error
    }
}
