import React from 'react';
import './Applications.css';
import Swiper from 'react-id-swiper';

import Caption from '../Caption/Caption';

class Applications extends React.Component{

    constructor(props){
        super(props);
        this.state={
            desc:[
                'Time constrained to read your favorite books! Build and customize audio book collection with voice of your choice. Add life to the audio books with varied human like emotions.',
                'Reading App reads the stories and novels to your kids/grandkids in their favorite voice. Carry collection of all your favorite books anywhere, anytime and everywhere all contained in a light weight device.',
                'Add personalized distinctive unique human like voice to your Robots for interacting and responding. Power up your Robot offline and online with neural voice of your choice. '
            ],
        }
    }

    renderCard(image,title,desc){
        return(
            <div className="card_outline application_card_outline">
                <div id="card_image_container">
                    <img id="card_image" src={image} alt="sudiobook_image"/>
                </div>
                <div id="card_info_container">
                    <p id="card_info_title">
                        {title}
                    </p>
                    <p id="card_info_desc">
                        {desc}
                    </p>
                </div>
            </div>
        );
    }

    render(){
        const params = {
            loop:true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
          }
        return(
            <div id="applications">
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption color={'white'} title={'Business Applications'} 
                    description={'Try, use and build your business applications with our voice platform “Larynx.ai”. We power up your business with '+
                    'our voice to interact with your customers.'}/> 
                </div>
                <div className="applications_swiper_container">
                    {/* <Swiper {...params}>
                        <div id="applications_slide_container">
                            {this.renderCard(require('../../images/audiobook.jpg'),'Build Audiobooks')}
                            {this.renderCard(require('../../images/read_app.png'),'Reading Apps')}
                            {this.renderCard(require('../../images/power_robot.jpg'),'Power your Robot')}
                        </div>
                        <div id="applications_slide_container">
                            {this.renderCard(require('../../images/audiobook.jpg'))}
                            {this.renderCard(require('../../images/read_app.png'))}
                            {this.renderCard(require('../../images/power_robot.jpg'))}
                        </div>
                    </Swiper> */}
                    <div id="applications_slide_container">
                            {this.renderCard(require('../../images/audiobook.jpg'),'Build Audiobooks',this.state.desc[0])}
                            {this.renderCard(require('../../images/read_app.png'),'Reading Apps',this.state.desc[1])}
                            {this.renderCard(require('../../images/power_robot.jpg'),'Power your Robot',this.state.desc[2])}
                        </div>
                </div>
            </div>
        );
    }
}

export default Applications;