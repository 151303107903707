import React from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  ContentState,
} from "draft-js";
import { trackPromise } from "react-promise-tracker";
import { HOST } from "../../constants/constants";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import Dropdown from "react-bootstrap/Dropdown";
import AudioPlayer from "react-h5-audio-player";
import Loader from "react-loader-spinner";
import "./Editor.css";

//connect to Store
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getModalDetails from "../../data/actions/getModalDetails";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";


var aws = require('aws-sdk');

aws.config.update({
  region: 'ap-south-1', // Put your aws region here
  accessKeyId: 'AKIAWYSIT4ISTWCAZXFS',
  secretAccessKey: 'TsIAcxV/2SvwQ0M7oUXwgdhYEj/DV6IB1uzdaQDV',
})

var s3 = new aws.S3({ params: { Bucket: "zdlab-tts" }, region: 'ap-south-1' });


//mapping state
function mapStateToProps(state) {
  return {
    MODALDETAILS: state.getModalDetailsReducer,
  };
}
//mapping props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModalDetails,
    },
    dispatch
  );
}

const colorStyleMap = {
  sayascar: {
    color: "white",
    background: "#2E207C",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<sayascar>",
    closeTag: "</sayascar>",
  },
  sayasord: {
    color: "white",
    background: "#291C6F",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<sayasord>",
    closeTag: "</sayasord>",
  },
  sayasmis: {
    color: "white",
    background: "#241963",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<sayasmis>",
    closeTag: "</sayasmis>",
  },
  phoneme: {
    color: "white",
    background: "#201656",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<phoneme>",
    closeTag: "</phoneme>",
  },
  sub: {
    color: "white",
    background: "#423689",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<sub>",
    closeTag: "</sub>",
  },
  prosodyrate: {
    color: "#E5E5E4",
    background: "#2E207C",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<prosodyrate>",
    closeTag: "</prosodyrate>",
  },
  prosodysilentvol: {
    color: "#FFFFFE",
    background: "#2E207C",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<prosodysilentvol>",
    closeTag: "</prosodysilentvol>",
  },
  prosodymediumvol: {
    color: "#FFFFFE",
    background: "#2E207C",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<prosodymediumvol>",
    closeTag: "</prosodymediumvol>",
  },
  prosodyhighvol: {
    color: "#FFFFFE",
    background: "#2E207C",
    padding: "5px",
    borderRadius: "100px",
    openTag: "<prosodyhighvol>",
    closeTag: "</prosodyhighvol>",
  },
  bbold: {
    fontWeight: "700",
    openTag: "<prosodyhighvol>",
    closeTag: "</prosodyhighvol>",
  },
};

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    var editorState = this.props.location.state.blip
      ? EditorState.createWithContent(
        ContentState.createFromText(this.props.location.state.blip.text)
      )
      : EditorState.createEmpty();
    this.state = {
      userdata: JSON.parse(localStorage.getItem("user_data")),
      project: this.props.location.state.project,
      editorState: editorState,
      switchEditor: false,
      modals: [],
      nameArray: [],
      styleArray: [],
      ageArray: [],
      languageArray: [],
      genderArray: [],
      selectedLanguage: null,
      selectedGender: null,
      selectedAge: null,
      selectedStyle: null,
      selectedName: null,
      selectedModal: null,
      playerModal: false,
      linkModal: false,
      playerData: null,
      audioReady: false,
      createFlag: this.props.location.state.blip ? false : true,
      isGettingText: false,
      link: null,
      linkForHash: null,
      audioPathForPublish: null,
    };
    console.log("Editor page user id" + localStorage.getItem("user_data").id);
    this.onChange = (editorState) => {
      this.setState({ editorState });
    };
  }

  componentDidMount() {
    // console.log(this.props)
    this.props.getModalDetails(this.state.userdata);
    if (this.props.location.state.blip) {
      var filename = this.props.location.state.blip.audio_url.split("/")[
        this.props.location.state.blip.audio_url.split("/").length - 1
      ];
      var tempPlayerData = {
        filename: filename,
        url: this.props.location.state.blip.audio_url,
        blip_id: this.props.location.state.blip.blip_id,
      };
      this.setState((state, props) => {
        return {
          playerData: tempPlayerData,
        };
      });
    }
    // this.makeArrays(0,this.state.modals.modals,{})
  }

  static getDerivedStateFromProps(nextprops, prevstate) {
    if (nextprops.MODALDETAILS.data && nextprops.MODALDETAILS.data.response) {
      // console.log(nextprops.MODALDETAILS.data)
      return {
        isfetching: nextprops.MODALDETAILS.isfetching,
        modals: nextprops.MODALDETAILS.data.response,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(this.state.userdata)
    if (
      this.props.MODALDETAILS !== prevProps.MODALDETAILS &&
      this.state.modals.length > 0
    ) {
      this.makeArrays(0, this.state.modals, {});
    }
  }

  logSelectionState() {
    const { editorState } = this.state;
    console.log(editorState.getCurrentContent().getPlainText());
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    console.log(currentContentBlock.getText().slice(start, end));
  }

  // handleKeyCommand(command, editorState) {
  //     console.log(command)
  //     console.log(editorState)
  //     const newState = RichUtils.handleKeyCommand(editorState, command);
  //     if (newState) {
  //         this.onChange(newState);
  //         return 'handled';
  //     }
  //     return 'not-handled';
  // }

  // ontextChange(value){
  //     console.log(value)
  //     this.setState((state,props)=>{
  //         return{
  //             editorState:value
  //         }
  //     })
  // }

  setNewVoice() {
    this.setState((state, props) => {
      return {
        createFlag: true,
        audioReady: false,
      };
    });
  }

  editVoice() {
    console.log("edit");
    this.setState({ audioReady: false });
    var text = this.state.editorState.getCurrentContent().getPlainText();
    text = text.replace(/'/g, "%27");
    trackPromise(
      fetch(HOST + "/api_handler.php?timestamp=" + new Date().getTime(), {
        method: "POST",
        headers: {
          gateway: "0",
          path: "text2speech/create/blip/edit",
          method: "POST",
          token: this.state.userdata.token,
        },
        body: JSON.stringify({
          project_id: this.state.project.project_id,
          text: text,
          filename: this.state.playerData.filename,
          blip_id: this.state.playerData.blip_id,
          model: this.state.selectedModal.model_id,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonresponse) => {
          console.log(jsonresponse);
          this.setState((state, props) => {
            return {
              playerData: jsonresponse.length > 0 ? jsonresponse[0] : null,
              playerModal: true,
              createFlag: false,
            };
          });
          this.checkS3File(jsonresponse[0].url);
        })
        .catch((error) => {
          console.log(error);
        }),
      "modal_spinner"
    );
  }

  generateVoice() {
    console.log("create");
    var text = this.state.editorState.getCurrentContent().getPlainText();
    text = text.replace(/'/g, "%27");
    // console.log(text);
    trackPromise(
      fetch(HOST + "/api_handler.php?timestamp=" + new Date().getTime(), {
        method: "POST",
        headers: {
          gateway: "0",
          path: "text2speech/create/blip",
          method: "POST",
          token: this.state.userdata.token,
        },
        body: JSON.stringify({
          project_id: this.state.project.project_id,
          text: text,
          model: this.state.selectedModal.model_id,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonresponse) => {
          console.log(jsonresponse);
          this.setState((state, props) => {
            return {
              playerData: jsonresponse.length > 0 ? jsonresponse[0] : null,
              playerModal: true,
              createFlag: false,
            };
          });
          this.setState({ audioPathForPublish: jsonresponse[0].url });
          this.checkS3File(jsonresponse[0].url);
        })
        .catch((error) => {
          console.log(error);
        }),
      "modal_spinner"
    );
  }

  generateNewsContent() {
    console.log("Get news content");
    this.setState({ isGettingText: true });
    // console.log(text);
    trackPromise(
      fetch(HOST + "/api_handler.php?timestamp=" + new Date().getTime(), {
        method: "POST",
        headers: {
          gateway: "0",
          path: "fetch/web/data",
          method: "POST",
          // token: this.state.userdata.token,
        },
        body: JSON.stringify({
          url: this.state.link,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonresponse) => {
          console.log(jsonresponse);
          this.setState({ isGettingText: false });
          if (jsonresponse.message == "success") {
            this.handleLinkModal();
            this.setState({ linkForHash: this.state.link });
            //set editor here
            var editorState = EditorState.createWithContent(ContentState.createFromText(jsonresponse.response.content + "."));
            this.onChange(editorState)
            //alert(jsonresponse.response.content)
          }
        })
        .catch((error) => {
          console.log(error);
        }),
    );
  }

  checkS3File(url) {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status != 200) {
          setTimeout(() => this.checkS3File(url), 4000);
        } else {
          this.setState((state, props) => {
            return {
              audioReady: true,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleColor(event, style) {
    const { editorState } = this.state;
    if (editorState.getSelection().hasFocus) {
      event.preventDefault();
      const { editorState } = this.state;
      const selection = editorState.getSelection();

      // Let's just allow one color at a time. Turn off all active colors.
      const nextContentState = Object.keys(colorStyleMap).reduce(
        (contentState, color) => {
          return Modifier.removeInlineStyle(contentState, selection, color);
        },
        editorState.getCurrentContent()
      );

      let nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        "change-inline-style"
      );

      const currentStyle = editorState.getCurrentInlineStyle();

      // Unset style override for current color.
      if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce((state, color) => {
          return RichUtils.toggleInlineStyle(state, color);
        }, nextEditorState);
      }

      // If the color is being toggled on, apply it.
      if (!currentStyle.has(style)) {
        nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
      }

      this.onChange(nextEditorState);
    } else {
      console.log("No selection on editor");
    }
  }

  toggleBold(event, style) {
    event.preventDefault();
    this.setState({
      editorState: RichUtils.toggleInlineStyle(this.state.editorState, style),
    });
  }

  getSelection() {
    var selectionState = this.state.editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = this.state.editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    // console.log(currentContentBlock.getText().slice(start,end));
  }

  handlePlayerModal() {
    this.setState((state, props) => {
      return {
        playerModal: false,
      };
    });
  }

  handleLinkModal() {
    if (!this.state.isGettingText) {
      this.setState((state, props) => {
        return {
          linkModal: false,
        };
      });
    }
  }


  renderPlayerModal() {
    return (
      <div>
        <Modal
          size="lg"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            this.handlePlayerModal();
          }}
          show={this.state.playerModal}
        >
          <div style={{ outline: "none" }} className="editor_playmodal_container">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0% 0% 2% 0%",
              }}
            >
              {/* <div onClick={()=>this.handlePlayerModal({})} style={{alignSelf:'right',color:'blue',cursor:'pointer'}}>close</div> */}
            </div>
            {this.state.audioReady && (
              <AudioPlayer
                src={
                  this.state.playerData
                    ? this.state.playerData.url +
                    "?random = " +
                    new Date().getTime()
                    : ""
                }
                onPlay={(e) => console.log("onPlay")}
              // other props here
              />
            )}
            {!this.state.audioReady && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Loader type="Oval" color="#30217F" width={80} height={80} />
                <div style={{ marginTop: "1%", color: "#30217F" }}>
                  Generating Voice...
              </div>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          size="lg"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // onHide={() => {
          //   this.handleLinkModal();
          // }}
          show={this.state.linkModal}
        >
          {this.state.isGettingText ? (<div className="modal_linkContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader type="Oval" color="#30217F" width={80} height={80} />
              <div style={{ marginTop: "1%", color: "#30217F" }}>
                Getting Content...
                </div>
            </div>
          </div>) : (<div className="modal_linkContainer">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '0% 0% 25px 0%' }}>
              <div onClick={() => this.handleLinkModal({})} style={{ alignSelf: 'right', color: 'blue', cursor: 'pointer' }}>close</div>
            </div>
            <input
              type="text"
              value={this.state.link}
              className="link_textbox"
              placeholder="Paste URL here..."
              onChange={(event) => {
                // console.log(evt.target.value)
                this.setState({ link: event.target.value });
              }}>
            </input>
            <div
              style={{ pointerEvents: (this.state.link == null) ? "none" : "auto" }}
              onClick={() => {
                // alert(this.state.link);
                this.generateNewsContent();
              }}
              className="editor_section2_button"
            >
              Get Text
              </div>
          </div>)}


        </Modal>
      </div>

    );
  }



  renderEditButton(...buttonDetails) {
    return (
      (buttonDetails[3] == undefined) ? (<div
        style={{ pointerEvents: "auto" }}
        onMouseDown={(event) => (buttonDetails[1] == "link") ? (this.setState({ linkModal: true })) : (this.toggleColor(event, buttonDetails[1]))}
        className="editor_editbutton_container"
      >
        <img id="editor_editbuttonimage" src={buttonDetails[2]} alt={buttonDetails[0]} />
        <div>{buttonDetails[0]}</div>
      </div>) : ((this.state.createFlag) ? null : (<div
        style={{ pointerEvents: buttonDetails[3] ? "none" : "auto" }}
        onClick={() => { this.setNewVoice(); }}
        // onMouseDown={(event) => this.toggleColor(event, buttonDetails[1])}
        className="editor_editbutton_container"
      >
        <img id="editor_editbuttonimage" src={buttonDetails[2]} alt={buttonDetails[0]} />
        <div>{buttonDetails[0]}</div>
      </div>))
    );
  }

  // renderEmphasisButton(edit, style, image) {
  //   return (
  //     <div
  //       style={{ pointerEvents: "none" }}
  //       onMouseDown={(event) => this.toggleBold(event, style)}
  //       className="editor_editbutton_container"
  //     >
  //       <img id="editor_editbuttonimage" src={image} alt={edit} />
  //       <div>{edit}</div>
  //     </div>
  //   );
  // }

  renderEmptyBox() {
    return <div className="editor_emptybuttoncontainer"></div>;
  }

  handleSwitch(change) {
    this.setState((state, props) => {
      return {
        switchEditor: change,
      };
    });
  }

  makeArrays(flag, temp_data, criteria) {
    temp_data = temp_data.filter(function (obj) {
      return Object.keys(criteria).every(function (c) {
        return obj[c] === criteria[c];
      });
    });

    var temp_languageArray = flag < 1 ? [] : [...this.state.languageArray];
    var temp_styleArray = flag < 2 ? [] : [...this.state.styleArray];
    var temp_genderArray = flag < 3 ? [] : [...this.state.genderArray];
    var temp_ageArray = flag < 4 ? [] : [...this.state.ageArray];
    var temp_nameArray = [];

    for (var i in temp_data) {
      if (flag < 1) {
        if (
          temp_languageArray.find((value) => {
            return value === temp_data[i].language;
          })
        ) {
        } else {
          temp_languageArray.push(temp_data[i].language);
        }
      }
      if (flag < 2) {
        if (
          temp_styleArray.find((value) => {
            return value === temp_data[i].style;
          })
        ) {
        } else {
          temp_styleArray.push(temp_data[i].style);
        }
      }
      if (flag < 3) {
        if (
          temp_genderArray.find((value) => {
            return value === temp_data[i].gender;
          })
        ) {
        } else {
          temp_genderArray.push(temp_data[i].gender);
        }
      }
      if (flag < 4) {
        if (
          temp_ageArray.find((value) => {
            return value === temp_data[i].age_group;
          })
        ) {
        } else {
          temp_ageArray.push(temp_data[i].age_group);
        }
      }
      if (
        temp_nameArray.find((value) => {
          return value === temp_data[i].name;
        })
      ) {
      } else {
        temp_nameArray.push(temp_data[i].name);
      }
    }
    this.setState((state, props) => {
      return {
        nameArray: temp_nameArray,
        styleArray: temp_styleArray,
        ageArray: temp_ageArray,
        languageArray: temp_languageArray,
        genderArray: temp_genderArray,
      };
    });

    // console.log(temp_languageArray)
    // console.log(temp_styleArray)
    // console.log(temp_genderArray)
    // console.log(temp_ageArray)
    // console.log(temp_nameArray)
    // console.log('=================================')
  }

  onLanguageSelect(key, event) {
    this.makeArrays(1, this.state.modals, {
      language: this.state.languageArray[key],
    });
    this.setState((state, props) => {
      var samevalue =
        state.selectedLanguage === state.languageArray[key] ? true : false;
      return {
        selectedLanguage: state.languageArray[key],
        selectedStyle: samevalue ? state.selectedStyle : null,
        selectedGender: samevalue ? state.selectedGender : null,
        selectedAge: samevalue ? state.selectedAge : null,
        selectedName: samevalue ? state.selectedName : null,
        selectedModal: samevalue ? state.selectedModal : null,
      };
    });
  }

  onStyleSelect(key, event) {
    this.makeArrays(2, this.state.modals, {
      language: this.state.selectedLanguage,
      style: this.state.styleArray[key],
    });
    this.setState((state, props) => {
      var samevalue =
        state.selectedStyle === state.styleArray[key] ? true : false;
      return {
        selectedStyle: state.styleArray[key],
        selectedGender: samevalue ? state.selectedGender : null,
        selectedAge: samevalue ? state.selectedAge : null,
        selectedName: samevalue ? state.selectedName : null,
        selectedModal: samevalue ? state.selectedModal : null,
      };
    });
  }

  onGenderSelect(key, event) {
    this.makeArrays(3, this.state.modals, {
      language: this.state.selectedLanguage,
      style: this.state.selectedStyle,
      gender: this.state.genderArray[key],
    });
    this.setState((state, props) => {
      var samevalue =
        state.selectedGender === state.genderArray[key] ? true : false;
      return {
        selectedGender: state.genderArray[key],
        selectedAge: samevalue ? state.selectedAge : null,
        selectedName: samevalue ? state.selectedName : null,
        selectedModal: samevalue ? state.selectedModal : null,
      };
    });
  }

  onAgeSelect(key, event) {
    this.makeArrays(4, this.state.modals, {
      language: this.state.selectedLanguage,
      style: this.state.selectedStyle,
      gender: this.state.selectedGender,
      age_group: this.state.ageArray[key],
    });
    this.setState((state, props) => {
      var samevalue = state.selectedAge === state.ageArray[key] ? true : false;
      return {
        selectedAge: state.ageArray[key],
        selectedName: samevalue ? state.selectedName : null,
        selectedModal: samevalue ? state.selectedModal : null,
      };
    });
  }

  onNameSelect(key, event) {
    var tempSelectedModal = null;
    for (var i in this.state.modals) {
      if (
        this.state.modals[i].name == this.state.nameArray[key] &&
        this.state.modals[i].age_group == this.state.selectedAge &&
        this.state.modals[i].gender == this.state.selectedGender &&
        this.state.modals[i].style == this.state.selectedStyle &&
        this.state.modals[i].language == this.state.selectedLanguage
      ) {
        tempSelectedModal = this.state.modals[i];
      }
    }
    this.setState((state, props) => {
      return {
        selectedName: state.nameArray[key],
        selectedModal: tempSelectedModal,
      };
    });
  }

  checkStyleDisable() {
    if (this.state.selectedLanguage == null) return true;
    else return false;
  }

  checkGenderDisable() {
    if (this.state.selectedStyle == null) return true;
    else return false;
  }

  checkAgeDisable() {
    if (this.state.selectedGender == null) return true;
    else return false;
  }

  checkNameDisable() {
    if (this.state.selectedAge == null) return true;
    else return false;
  }

  publishAudio() {

    var user_id = JSON.parse(localStorage.getItem("user_data")).id;
    var link = this.state.linkForHash;  //"https://www.doolnews.com/covid-19-india-today-list-cross-2-lacks-patients-444.html"
    var audioPath = this.state.audioPathForPublish;
    let newDate = new Date()
    let fullDate = newDate.getDate() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear();

    var hash = require('object-hash');
    var newHashedName = hash(link);


    console.log(user_id);
    console.log(fullDate);
    console.log(newHashedName);
    console.log(audioPath);

    var params = {
      Bucket: "zdlab-tts",
      CopySource: audioPath,
      Key: user_id + "/" + fullDate + "/" + newHashedName + ".mp3"
    };

    s3.copyObject(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else console.log("SUCCESS");
    });

  }

  generateText() {
    var blocks = convertToRaw(this.state.editorState.getCurrentContent())
      .blocks;
    console.log(blocks);
    for (var i in blocks) {
      // console.log(blocks[i])
      var styles = blocks[i].inlineStyleRanges;
      var text = blocks[i].text;
      if (text.endsWith(".")) {
        text = text.substring(0, text.length - 1);
      }

      //styleMap formation
      var styleMap = {};
      for (var j in styles) {
        if (Object.keys(styleMap).length == 0) {
          styleMap[styles[j].offset] = [colorStyleMap[styles[j].style].openTag];
          styleMap[styles[j].offset + styles[j].length] = [
            colorStyleMap[styles[j].style].closeTag,
          ];
        } else {
          if (styleMap[styles[j].offset]) {
            var tempOpenTags = styleMap[styles[j].offset];
            tempOpenTags.push(colorStyleMap[styles[j].style].openTag);
          } else {
            styleMap[styles[j].offset] = [
              colorStyleMap[styles[j].style].openTag,
            ];
          }

          if (styleMap[styles[j].offset + styles[j].length]) {
            var tempCloseTags = styleMap[styles[j].offset + styles[j].length];
            tempCloseTags.push(colorStyleMap[styles[j].style].closeTag);
          } else {
            styleMap[styles[j].offset + styles[j].length] = [
              colorStyleMap[styles[j].style].closeTag,
            ];
          }
        }
        // console.log(colorStyleMap[styles[j].style])
      }
      // console.log(styleMap)

      var formattedText = "";
      for (var a = 0; a <= text.length; a++) {
        if (styleMap[a]) {
          for (var b in styleMap[a]) {
            formattedText = formattedText + styleMap[a][b];
          }
        }
        if (a != text.length) {
          formattedText = formattedText + text.charAt(a);
        }
      }
      formattedText = this.parseText(formattedText);
      console.log(formattedText);

      console.log(colorStyleMap);
      formattedText = this.removeEmptyTags(formattedText);
      console.log(formattedText);

      // Attaching paragraph and sentence tags to the end.
      formattedText = "<p><s>" + formattedText + "</s></p>";
      formattedText = formattedText.replace(/\./g, "</s><s>");
      console.log(formattedText);
    }
  }

  removeEmptyTags(formattedText) {
    for (var i of Object.keys(colorStyleMap)) {
      var emptyTags = colorStyleMap[i].openTag + colorStyleMap[i].closeTag;
      formattedText = formattedText.replace(emptyTags, "");
    }
    return formattedText;
  }

  parseText(formattedText) {
    var breakFun = false;
    var text = formattedText;
    var tagStack = [];
    for (var m = 0; m < text.length; m++) {
      if (text.charAt(m) == "<") {
        var n = m;
        var tag = "";
        do {
          tag = tag + text.charAt(n);
          n++;
        } while (text.charAt(n - 1) != ">");
        if (tag.charAt(1) != "/") {
          tagStack.push(tag);
        } else {
          var closeTagWord = new RegExp(tag.substring(2, tag.length - 1), "g");
          if (tagStack[tagStack.length - 1].match(closeTagWord) != null) {
            tagStack.pop();
          } else {
            var p1 =
              text.slice(0, m) +
              "</" +
              tagStack[tagStack.length - 1].substr(
                1,
                tagStack[tagStack.length - 1].length - 2
              ) +
              ">";
            var p2 = tagStack[tagStack.length - 1] + text.slice(n);
            text = p1 + tag + p2;
            breakFun = true;
          }
        }
        if (breakFun) {
          break;
        }
      }
    }
    if (breakFun) {
      return this.parseText(text);
    }
    return text;
  }

  // parseText(formattedText){
  //     var brokeFunction = false;
  //     var text = formattedText;
  //     var tagStack=[];
  //     for(var m = 0; m<text.length;m++){
  //         if(text.charAt(m) == '<'){
  //             var n = m;
  //             var tag="";
  //             do{
  //                 tag=tag+text.charAt(n);
  //                 n++;
  //             }while(text.charAt(n-1) != '>')
  //             if(tag.charAt(1) != '/'){
  //                 tagStack.push(tag);
  //             }else{
  //                 var closeTagWord = new RegExp(tag.substring(2, tag.length-1),"g");
  //                 if(tagStack[tagStack.length-1].match(closeTagWord) != null){
  //                     tagStack.pop();
  //                 }else{
  //                     var p1 = text.slice(0,m)+"</"+tagStack[tagStack.length-1].substr(1, tagStack[tagStack.length-1].length-2)+">";
  //                     var p2 = tagStack[tagStack.length-1]+text.slice(n);
  //                     text = p1+tag+p2
  //                     brokeFunction = true;
  //                 }
  //             }
  //         }
  //         if(brokeFunction){
  //             break;
  //         }
  //     }
  //     if(brokeFunction){
  //         this.parseText(text);
  //     }else{
  //         console.log(text)
  //     }

  // }

  render() {
    var disableButton =
      this.state.selectedModal == null ||
        this.state.editorState.getCurrentContent().getPlainText() == ""
        ? true
        : false;
    return (
      <div className="editor_screen">
        {this.renderPlayerModal()}
        <div className="editor_section1">
          <div className="editor_section1_top">
            <img
              onClick={() => this.props.history.goBack()}
              id="editor_backimage"
              src={require("../../images/back.png")}
              alt={"back"}
            />
          </div>
          <div className="editor_section1_buttonscontainer">
            {this.renderEditButton(
              "NewBlip",
              "addVoice",
              require("../../images/add.png"),
              disableButton
            )}
            {this.renderEditButton(
              "Link",
              "link",
              require("../../images/link.png")
            )}
            {/* {this.renderEditButton(
              "Say",
              "sayascar",
              require("../../images/say.png")
            )}
            {this.renderEditButton(
              "Substitute",
              "sub",
              require("../../images/substitute.png")
            )}
            {this.renderEditButton(
              "Phoneme",
              "phoneme",
              require("../../images/phoneme.png")
            )}
            {this.renderEditButton(
              "Break",
              "yellow",
              require("../../images/break.png")
            )}
            {this.renderEditButton(
              "Rate",
              "prosodyrate",
              require("../../images/rate.png")
            )}
            {this.renderEditButton(
              "Volume",
              "prosodyhighvol",
              require("../../images/volume.png")
            )}
            {this.renderEmphasisButton(
              "Emphasis",
              "prosodyhighvol",
              require("../../images/emphasis.png")
            )} */}
            {this.renderEmptyBox()}
          </div>
        </div>
        <div className="editor_section2">
          <div className="editor_section2_upper"></div>
          <div className="editor_textbox" onClick={this.focus}>
            <Editor
              customStyleMap={colorStyleMap}
              ref={(ref) => (this.editor = ref)}
              editorState={this.state.editorState}
              // handleKeyCommand={(command,editorState)=>this.handleKeyCommand(command,editorState)}
              onChange={this.onChange}
            />
          </div>
          <div className="editor_section2_lower">
            {!this.state.createFlag && (
              <div className="editor_playcontainer">
                <img
                  style={{ pointerEvents: disableButton ? "none" : "auto" }}
                  id="editor_playimage"
                  onClick={() =>
                    this.setState({ playerModal: true })
                  }
                  src={require("../../images/play.png")}
                  alt={"back"}
                />
              </div>
            )}
            {this.state.createFlag ? (
              <div
                style={{ pointerEvents: disableButton ? "none" : "auto" }}
                onClick={() => {
                  this.generateVoice();
                }}
                className="editor_section2_button"
              >
                Generate Voice
              </div>
            ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{ pointerEvents: disableButton ? "none" : "auto" }}
                    onClick={() => {
                      this.editVoice();
                    }}
                    className="editor_section2_split_button"
                  >
                    Edit Voice
                </div>
                  <div
                    style={{ pointerEvents: this.state.audioReady ? "auto" : "none" }}
                    onClick={() => {
                      this.publishAudio();
                    }}
                    className="editor_section2_split_button"
                  >
                    Publish
                </div>
                </div>
              )}
          </div>
        </div>
        <div className="editor_section3">
          {/* <label style={{display:'flex',alignItems:'center'}}>
                        <Switch
                            onColor="#F3913D"
                            height={25}
                            width={48}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            onChange={(change)=>this.handleSwitch(change)} checked={this.state.switchEditor} />
                        <span style={{marginLeft:'3%',cursor:'pointer'}}>Switch Plain Editor</span>
                    </label> */}
          <div className="editor_section3_separator"></div>
          <Dropdown
            onSelect={(key, event) => {
              this.onLanguageSelect(key, event);
            }}
          >
            <Dropdown.Toggle
              className="editor_section3_dropbutton"
              variant="Secondary"
              id="dropdown-basic"
            >
              <div className="editor_section3_droptext">
                {this.state.selectedLanguage !== null
                  ? this.state.selectedLanguage
                  : "Language"}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.languageArray.map((language, index) => {
                return (
                  <Dropdown.Item key={"language_" + language} eventKey={index}>
                    {language}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="editor_section3_separator"></div>
          <Dropdown
            onSelect={(key, event) => {
              this.onStyleSelect(key, event);
            }}
          >
            <Dropdown.Toggle
              disabled={this.checkStyleDisable()}
              className="editor_section3_dropbutton"
              variant="Secondary"
              id="dropdown-basic"
            >
              <div className="editor_section3_droptext">
                {this.state.selectedStyle !== null
                  ? this.state.selectedStyle
                  : "Style"}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.styleArray.map((style, index) => {
                return (
                  <Dropdown.Item key={"style_" + style} eventKey={index}>
                    {style}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="editor_section3_separator"></div>
          <Dropdown
            onSelect={(key, event) => {
              this.onGenderSelect(key, event);
            }}
          >
            <Dropdown.Toggle
              disabled={this.checkGenderDisable()}
              className="editor_section3_dropbutton"
              variant="Secondary"
              id="dropdown-basic"
            >
              <div className="editor_section3_droptext">
                {this.state.selectedGender !== null
                  ? this.state.selectedGender
                  : "Gender"}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.genderArray.map((gender, index) => {
                return (
                  <Dropdown.Item key={"gender_" + gender} eventKey={index}>
                    {gender}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="editor_section3_separator"></div>
          <Dropdown
            onSelect={(key, event) => {
              this.onAgeSelect(key, event);
            }}
          >
            <Dropdown.Toggle
              disabled={this.checkAgeDisable()}
              className="editor_section3_dropbutton"
              variant="Secondary"
              id="dropdown-basic"
            >
              <div className="editor_section3_droptext">
                {this.state.selectedAge !== null
                  ? this.state.selectedAge
                  : "Age group"}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.ageArray.map((age, index) => {
                return (
                  <Dropdown.Item key={"age_" + age} eventKey={index}>
                    {age}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="editor_section3_separator"></div>
          <Dropdown
            onSelect={(key, event) => {
              this.onNameSelect(key, event);
            }}
          >
            <Dropdown.Toggle
              disabled={this.checkNameDisable()}
              className="editor_section3_dropbutton"
              variant="Secondary"
              id="dropdown-basic"
            >
              <div className="editor_section3_droptext">
                {this.state.selectedName !== null
                  ? this.state.selectedName
                  : "Voice"}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.nameArray.map((name, index) => {
                return (
                  <Dropdown.Item key={"name_" + name} eventKey={index}>
                    {name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEditor);
