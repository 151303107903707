import React from 'react';
import './CorporateVoice.css'

import Caption from '../Caption/Caption';

class CorporateVoice extends React.Component{

    constructor(props){
        super(props);
        this.state={
            screenHeight:0,
            screenWidth:0,
            readMoreHowItWorks:false,
            benefits:[
                'Larynx.ai ‘s voice-enabled technology is very user friendly and has on the fly personalization. This empowers customers and their longevity in the market.',
                'We learn about fellow human beings with communication. The more you communicate the more accurate it becomes. The personalized distinctive unique voice brand listens and responds in human like emotions.',
                'Power up Announcement Services with 24*7 Availability of your Brand Voice.',
                'Larynx.ai voice-enabled technology is very user friendly. Dynamic, personalized and on the fly audio content can be built with minimal effort and cost.',
                'Larynx.ai ‘s Voice-enabled technology like Audio books can immensely contribute and revolutionize  visually impaired’s literacy development.'
            ],
            usecases:[
                'Larynx.ai, the voice platform builds unique distinctive voice by which customers recognize your Brand, Service and Company. Voice created  can then be integrated to IVR. Human like interaction by IVR will become the user interface of choice. It can tremendously improve the user experience and can replace web-based business interactions. Voice enabled multilingual IVR System will Reduce operational costs.',

                'Larynx.ai can tremendously benefit the Airline Industry. We build voice unique and distinct for the Airline Brand which customers recognize.  The voice can be integrated with the existing Airline System seamlessly. Thus Empowering Airline with Intelligent, dynamic Voice Enable announcements. The voice has multilingual functionality which improves the passenger experience in busy airport environment with seamless journey.'+ 
                ' Passengers are seeking to take control of their travel experience in very fast paced travel environment. Self service passenger processing solutions enabled with  multilingual functionality improves the passenger experience in busy airport environment with seamless journey. This will help serve the passengers better, faster and more efficiently; thus reducing operational cost and increasing terminal capacity.',

                'Larynx.ai will cater to the increased demand for Apps with Unique distinct human like voice to communicate with their smartphone users. The Voice can be integrated to the App in simple steps using the Larynx.ai Software Development Kit (SDK). Customers can select the languages of their  choice from the multilingual option. The Voice platform will save lots of time by having human like conversation with Smartphone Apps. Thus increasing the customer satisfaction and empowering them with hands / eyes free experience.',

                'Larynx.ai integrated with Reporting System will create an edge for your business. The audio visual element always captures and retains human attention.  the Unique distinct human like voice while reading out reports will make corporate users more active participants. Some users learn more by hearing than reading a piece of text, thus the Audio reports will help users to understand the content and crux of the report with additional information. Multilingual audio option adds extra edge to reporting system for corporate users language choice.'
            ],
        }
        window.addEventListener("resize", this.update);
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.update();
    }

    update=()=>{
        this.setState((state,props)=>{
            return{
                screenHeight:window.innerHeight,
                screenWidth:window.innerWidth
            }
        })
    }
    componentWillUnmount(){
        window.removeEventListener("resize",this.update)
    }

    renderHowItWorks(){
        return(
            <div className="corporate_howitworks_container">
                <div className="corporate_howitworks_title_container">
                    <p className="corporate_howitworks_title">What do you mean by corporate voice ?</p>
                </div>
                <div className="corporate_howitworks_desc_container">
                    <p>
                        We build unique voice by which customers recognize your Brand, Service and Company.
                    </p>
                    <p>
                        Larynx.ai will create Corporate Voice by understanding the core values of Company’s Brand for the target audience of the platform. 
                    </p>
                    {this.state.readMoreHowItWorks && <p>
                        The Neural Voice will convey Company's brand personality to the external audience with expressive human like voice. This Voice can be deployed and scaled on the choice of your infrastructure.
                    </p>}
                    <p onClick={()=>this.setState({readMoreHowItWorks:!this.state.readMoreHowItWorks})} id="corporate_howitworks_readmore">{(this.state.readMoreHowItWorks)? 'SEE LESS' : 'READ MORE'}</p>
                </div>
            </div>
        );
    }

    renderBenefitCard(title,desc){
        return(
            <div className="cv_benefits_cardoutline">
                <div className="cv_benefits_imagecontainer">
                    <img className="benefit_image" src={require('../../images/vector-tech-circle-technology-background_43778-322.jpg')} alt={'benefit'} />
                    
                </div>
                <div className="cv_benefits_infocontainer">
                    <p style={{}} id="cv_benefit_title">{title}</p>
                    <p style={{}} id="cv_benefit_desc">{desc}</p>
                </div>
            </div>
        );
    }

    renderBenefits(){
        return(
            <div className="corporatevoice_benefits_container">
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption color={'white'} title={'Benefits'} description={'Larynx.ai voice enabled technology is very easy to learn and use even by vision impaired. Thus building trust and empowering customers.'}/> 
                </div>
                <div className="cv_benefits_cardcontainer">
                    {this.renderBenefitCard('Customer Empowerement',this.state.benefits[0])}
                    {this.renderBenefitCard('Customer Trust',this.state.benefits[1])}
                    {/* {this.renderBenefitCard('24*7 Availability',this.state.benefits[2])} */}
                    {this.renderBenefitCard('Effortless',this.state.benefits[3])}
                    {this.renderBenefitCard('Empower Vision Impaired',this.state.benefits[4])}
                </div>
            </div>
        );
    }

    renderFlowDiagram(){
        return(
            <div className="cv_flowdiagram_container">
                <img className="cv_flowdigram_image" src={require('../../images/how_it_works.jpg')} alt={'how_it_works'}/>
            </div>
        );
    }

    renderLeftImageUseCase(title,desc){
        return(
            <div className="cv_usecase_outline">
                <div className="cv_usecase_imagecontainer">
                    <img 
                    className="cv_usecase_image"
                    alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                </div>
                <div className="cv_usecase_separator"></div>
                <div className="cv_usecase_infocontainer">
                    <p className="cv_usecase_title">{title}</p>
                    <p className="cv_usecase_desc">
                        {desc}
                    </p>
                </div>
                <div className="cv_usecase_separator_bottom"></div>
            </div>
        );
    }

    renderRightImageUseCase(title,desc){
        return(
            <div className="cv_usecase_outline">
                {this.state.screenWidth <=700 &&
                    <div className="cv_usecase_imagecontainer">
                        <img 
                        className="cv_usecase_image"
                        alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                    </div>
                }
                <div className="cv_usecase_infocontainer">
                    <p className="cv_usecaseright_title">{title}</p>
                    <p className="cv_usecaseright_desc">
                        {desc}
                    </p>
                </div>
                <div className="cv_usecase_separator"></div>
                {this.state.screenWidth >700 &&
                    <div className="cv_usecase_imagecontainer">
                        <img 
                        className="cv_usecase_image"
                        alt={'usecaes_image'} src={require('../../images/usecase.png')}/>
                    </div>
                }
                <div className="cv_usecase_separator_bottom"></div>
            </div>
        );
    }

    renderUseCases(){
        var usecases = this.state.usecases;
        return(
            <div style={{borderTop:'1px solid grey',borderBottom:'1px solid grey'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption title={'Use Cases'} description={'Larynx.ai can be integrated to enhance your business requirements. There are Limitless Use Cases for integrating your Smart Business Solution to Larynx.ai. Some sample Use Cases are listed below to inspire and innovate your Business Solution.'}/> 
                </div>
                <div className="cv_usecases_container">
                    {this.renderLeftImageUseCase('Interactive Voice Response (IVR)',usecases[0])}
                    {this.renderRightImageUseCase('Airline Announcements',usecases[1])}
                    {this.renderLeftImageUseCase('Smartphone App',usecases[2])}
                    {this.renderRightImageUseCase('Reports',usecases[3])}
                </div>
            </div>
        );
    }

    renderBlogCard(){
        return(
            <div className="cv_blog_cardoutline">
                <div>
                    <img src={require('../../images/blog.png')} alt={'blog'} className="cv_blog_image"/>
                </div>
                <div>
                    <p id="cv_benefit_title">Blog</p>
                </div>
            </div>
        );
    }

    renderBlogs(){
        return(
            <div className="corporatevoice_benefits_container">
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <Caption color={'white'} title={'Blogs'} description={'This text is a small description regarding the following topic discussed.'}/> 
                </div>
                <div className="cv_benefits_cardcontainer">
                    {this.renderBlogCard()}
                    {this.renderBlogCard()}
                    {this.renderBlogCard()}
                </div>
            </div>
        );
    }

    render(){
        return(
            <div className="corporatevoice">
                <div className="swiper_slide">
                    <div className="swiper_slide_frame">
                        <div className="swiper_slide_headings_black">
                            <h1 style={{fontWeight:'bold',}}>Stand-out in business</h1>
                            {this.state.screenWidth <= 700 &&
                                <img className="swiper_slide_image" src={require('../../images/duckies.png')} alt="duckies" />
                            }
                            <h4 style={{color:'grey'}}>Larynx.ai will help you build unique corporate voice brands.</h4>
                        </div>
                        <div className="swiper_slide_buttons">
                            <button className="swiper_button swiper_mainimage_button">Build Your Brand</button>
                        </div>
                    </div>
                    {this.state.screenWidth > 700 &&
                        <div className="swiper_slide_frame2">
                            <img className="swiper_slide_image" src={require('../../images/duckies.png')} alt="duckies" />
                        </div>
                    }
                </div>
                <div>
                    {this.renderHowItWorks()}
                    {this.renderBenefits()}
                    {this.renderFlowDiagram()}
                    {this.renderUseCases()}
                    {this.renderBlogs()}
                </div>
            </div>
        );
    }
}

export default CorporateVoice;